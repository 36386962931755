import { Modal } from "react-bootstrap";
import { AppVersion } from "./Version";
import { ReactComponent as UpdateIcon } from "./images/arrow-clockwise.svg";
import { skipWaitingAndReload, UpdateReloadNotificationStorageValue } from "./Constants";

interface Props {
    show: boolean;
    setShow: Function;
    apiVersion: string;
}
function Settings({ show, setShow, apiVersion }: Props) {
    return (
        <>
            <Modal size="sm" centered={true} show={show} onHide={() => setShow(false)} aria-labelledby="settingsModal">
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title id="settingsModalLabel">Settings</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-1">App Version: {AppVersion}</div>
                    {apiVersion && <div className="my-1">Api Version: {apiVersion}</div>}
                    <div className="my-1 d-flex align-items-center">
                        <div>Force Update: </div>
                        <button
                            type="button"
                            className="btn btn-success btn-sm ms-2"
                            onClick={async () => {
                                UpdateReloadNotificationStorageValue(false);
                                await skipWaitingAndReload();
                            }}
                        >
                            <UpdateIcon className="m-0" width="20" height="20" />
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Settings;
