import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";
import { AzureFunctionUri, checkUserRole, useQuery } from "./Constants";
import { useHistory } from "react-router-dom";
import { AlertType } from "./AlertMessage";
import EventUsersModel from "./models/EventUsersModel";
import { Modal } from "react-bootstrap";

interface Props {
  userRole: UserRoleModel;
  setAlertMessage: Function;
}
function EventUsers({ userRole, setAlertMessage }: Props) {
  const [loading, setLoading] = useState(true);
  const [eventId, setEventId] = useState<string | undefined>(undefined);
  const [otherUsersList, setOtherUsersList] = useState([] as EventUsersModel[]);
  const [usersList, setUsersList] = useState([] as EventUsersModel[]);
  const [facilitatorsList, setFacilitatorsList] = useState([] as EventUsersModel[]);

  const [addingUser, setAddingUser] = useState<EventUsersModel | undefined>();
  const [deletingUser, setDeletingUser] = useState<EventUsersModel | undefined>();

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const isMountedRef = useRef(true);
  const query = useQuery();
  const history = useHistory();

  function handleInputChange(formEvent: any) {
    const target = formEvent.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (!target.value) {
      setAddingUser(undefined);
      return;
    }

    var newEventUser: any;
    if (addingUser) {
      newEventUser = { ...addingUser };
    } else {
      newEventUser = { role: UserRoles.Other } as EventUsersModel;
    }
    if (newEventUser) {
      newEventUser[name] = value;
      setAddingUser(newEventUser);
    }
  }

  const getEventUsers = useCallback(
    async (eventId: string) => {
      if (isLoading || (!isLoading && !isAuthenticated)) return;
      if (!checkUserRole(userRole, [UserRoles.Admin, UserRoles.EventOwner])) return;

      isMountedRef.current = true;
      setLoading(true);

      const accessToken = await getAccessTokenSilently();

      fetch(AzureFunctionUri + "api/EventUsers/" + encodeURIComponent(eventId), {
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok. EventUsers (GET: EventUsers): ${response.statusText}(${response.status})`
            );
          }
          return response.json();
        })
        .then((data: EventUsersModel[]) => {
          if (isMountedRef.current) {
            let others: EventUsersModel[] = [];
            let users: EventUsersModel[] = [];
            let facilitators: EventUsersModel[] = [];
            data.forEach((eventUser) => {
              switch (eventUser.role) {
                case UserRoles.Other:
                  others.push(eventUser);
                  break;
                case UserRoles.User:
                  users.push(eventUser);
                  break;
                case UserRoles.Facilitator:
                  facilitators.push(eventUser);
                  break;
                default:
                  break;
              }
            });

            setOtherUsersList(others);
            setUsersList(users);
            setFacilitatorsList(facilitators);

            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          if (isMountedRef.current) setLoading(false);
        });
    },
    [getAccessTokenSilently, isAuthenticated, isLoading, userRole]
  );

  async function deleteEventUser(deletingUser: EventUsersModel) {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    if (!checkUserRole(userRole, [UserRoles.Admin, UserRoles.EventOwner])) return;
    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    fetch(AzureFunctionUri + "api/EventUsers/" + deletingUser.event_id, {
      method: "DELETE",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify(deletingUser),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. EventUsers (DELETE: EventUsers): ${response.statusText}(${response.status})`
          );
        }

        if (isMountedRef.current) {
          setLoading(false);
          setAlertMessage({
            message: "Successfully deleted event user.",
            messageType: AlertType.SuccessMessage,
          });

          getEventUsers(eventId ?? "");
          setDeletingUser(undefined);
        }
      })
      .catch((error) => {
        console.error(error);
        if (isMountedRef.current) {
          setLoading(false);
          setAlertMessage({
            message: "An error occurred while deleting event user.",
            messageType: AlertType.ErrorMessage,
          });
          setDeletingUser(undefined);
        }
      });
  }

  async function submitEvent(formEvent: any) {
    formEvent.preventDefault();

    if (isLoading || (!isLoading && !isAuthenticated)) return;
    if (!checkUserRole(userRole, [UserRoles.Admin, UserRoles.EventOwner])) return;
    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    fetch(AzureFunctionUri + "api/EventUsers/" + eventId, {
      method: "POST",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
      body: JSON.stringify(addingUser),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. EventUsers (PUT: EventUsers): ${response.statusText}(${response.status})`
          );
        }
        if (isMountedRef) {
          setAlertMessage({
            message: "Successfully added user.",
            messageType: AlertType.SuccessMessage,
          });
          getEventUsers(eventId ?? "");
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setAlertMessage({
          message: "An error has occurred. Please contact an admin.",
          messageType: AlertType.ErrorMessage,
        });
        setLoading(false);
        history.push("/Events");
      });
  }

  useEffect(() => {
    if (query.has("id")) {
      var eventId = query.get("id");
      if (eventId) {
        setEventId(eventId);
        getEventUsers(eventId);
      }
    } else {
      setAlertMessage({
        message: "Failed to get event users.",
        messageType: AlertType.ErrorMessage,
      });
      setLoading(false);
      history.push("/Events");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userRole]);

  return (
    <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
      <h1 className="pt-3">Event Users {eventId && <span>({eventId})</span>}</h1>
      {!isLoading && isAuthenticated && (
        <>
          {loading && (
            <div className="row m-2" id="loading-spinner">
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          )}
          {!loading && (
            <>
              <form onSubmit={submitEvent}>
                <div className="row mb-2 align-items-center">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="emailInput"
                      placeholder="Email Address"
                      value={addingUser?.email ?? ""}
                      name="email"
                      onChange={handleInputChange}
                      required
                      disabled={loading}
                    />
                  </div>
                </div>
                <div className="row mb-2 align-items-center">
                  <div className="col-8">
                    <select
                      className="form-select"
                      aria-label="Assign User Role"
                      name="role"
                      value={addingUser?.role}
                      disabled={loading || !addingUser}
                      onChange={handleInputChange}
                    >
                      <option value={UserRoles.Other}>Non-User</option>
                      <option value={UserRoles.Facilitator}>Facilitator</option>
                      <option value={UserRoles.User}>User</option>
                    </select>
                  </div>
                  <div className="col-4 d-flex justify-content-end">
                    <button className="btn btn-primary" disabled={loading || !addingUser}>
                      Add
                    </button>
                  </div>
                </div>
              </form>
              <div className="row">
                <table className="table align-middle">
                  <thead>
                    <tr>
                      <th scope="col" className="col-2"></th>
                      <th scope="col" className="col-7">
                        Users
                      </th>
                      <th scope="col" className="col-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList.length === 0 && (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No users
                        </td>
                      </tr>
                    )}
                    {usersList.map((eventUser: EventUsersModel, index: number) => {
                      return (
                        <tr key={"user" + index}>
                          <td>{index + 1}</td>
                          <td style={{ wordBreak: "break-all" }}>
                            {eventUser.email}{" "}
                            {eventUser.activated && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="green"
                                className="bi bi-check-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                              </svg>
                            )}
                          </td>
                          <td className="d-flex justify-content-end">
                            <button className="btn btn-danger" onClick={() => setDeletingUser(eventUser)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <table className="table align-middle">
                  <thead>
                    <tr>
                      <th scope="col" className="col-2"></th>
                      <th scope="col" className="col-7">
                        Facilitators
                      </th>
                      <th scope="col" className="col-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {facilitatorsList.length === 0 && (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No facilitators
                        </td>
                      </tr>
                    )}
                    {facilitatorsList.map((eventUser: EventUsersModel, index: number) => {
                      return (
                        <tr key={"facilitator" + index}>
                          <td>{index + 1}</td>
                          <td style={{ wordBreak: "break-all" }}>
                            {eventUser.email}{" "}
                            {eventUser.activated && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="green"
                                className="bi bi-check-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                              </svg>
                            )}
                          </td>
                          <td className="d-flex justify-content-end">
                            <button className="btn btn-danger" onClick={() => setDeletingUser(eventUser)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <table className="table align-middle">
                  <thead>
                    <tr>
                      <th scope="col" className="col-2"></th>
                      <th scope="col" className="col-7">
                        Other
                      </th>
                      <th scope="col" className="col-3"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {otherUsersList.length === 0 && (
                      <tr>
                        <td colSpan={3} className="text-center">
                          No other users
                        </td>
                      </tr>
                    )}
                    {otherUsersList.map((eventUser: EventUsersModel, index: number) => {
                      return (
                        <tr key={"otherUser" + index}>
                          <td>{index + 1}</td>
                          <td style={{ wordBreak: "break-all" }}>
                            {eventUser.email}{" "}
                            {eventUser.activated && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="green"
                                className="bi bi-check-circle"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                              </svg>
                            )}
                          </td>
                          <td className="d-flex justify-content-end">
                            <button className="btn btn-danger" onClick={() => setDeletingUser(eventUser)}>
                              Delete
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          )}
          <Modal
            centered={true}
            show={deletingUser !== undefined}
            onHide={() => setDeletingUser(undefined)}
            aria-labelledby="deleteEventUserModal"
          >
            <Modal.Header className="py-2" closeButton>
              <Modal.Title id="deleteEventUserModalLabel">Delete Event User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="my-1">
                Are you sure you want to delete this Event User? <br />
                Event Id: {deletingUser?.event_id} <br />
                Name: {deletingUser?.email} <br />
                Role: {deletingUser?.role} <br />
                <b>Note: </b> Deleting a user here will NOT delete thier profile if they have already activated their
                account. Delete their account in the 'Manage Users' page
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={() => setDeletingUser(undefined)}>
                Cancel
              </button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  if (deletingUser) {
                    deleteEventUser(deletingUser);
                    setDeletingUser(undefined);
                  }
                }}
                disabled={loading || !deletingUser}
              >
                Delete
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
}

export default EventUsers;
