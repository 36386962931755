import { useAuth0 } from "@auth0/auth0-react";
import { useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { AlertType } from "./AlertMessage";
import { AzureFunctionUri, checkUserRole } from "./Constants";
import EventModel from "./models/EventModel";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";

interface Props {
  userRole: UserRoleModel;
  setAlertMessage: Function;
}
function CreateEvent({ userRole, setAlertMessage }: Props) {
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState<EventModel | undefined>(undefined);

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const isMountedRef = useRef(true);
  const history = useHistory();

  function handleInputChange(formEvent: any) {
    const target = formEvent.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var newEvent: any;
    if (event) {
      newEvent = { ...event };
    } else {
      newEvent = {} as EventModel;
    }
    if (newEvent) {
      newEvent[name] = value;
      setEvent(newEvent);
    }
  }

  function submitEvent(formEvent: any) {
    formEvent.preventDefault();

    if (isLoading || (!isLoading && !isAuthenticated)) return;
    if (!checkUserRole(userRole, [UserRoles.Admin])) return;
    isMountedRef.current = true;
    setLoading(true);

    getAccessTokenSilently().then((token) => {
      fetch(AzureFunctionUri + "api/Events/" + event?.event_id, {
        method: "PUT",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(event),
      })
        .then((response) => {
          if (response.status === 409) {
            setAlertMessage({
              message: "Event code already exists, please choose a different code.",
              messageType: AlertType.ErrorMessage,
            });
            isMountedRef.current = false;
            setLoading(false);
          } else if (!response.ok) {
            throw new Error(
              `Network response was not ok. CreateEvent (PUT: Events): ${response.statusText}(${response.status})`
            );
          } else {
            isMountedRef.current = false;
            setAlertMessage({
              message: "Successfully created event.",
              messageType: AlertType.SuccessMessage,
            });
            history.push("/Events");
          }
        })
        .catch((error) => {
          console.error(error);
          isMountedRef.current = false;
          setLoading(false);
          setAlertMessage({
            message: "Failed to create event.",
            messageType: AlertType.ErrorMessage,
          });
        });
    });
  }

  return (
    <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
      <h1 className="pt-3">Create Event</h1>
      {!isLoading && isAuthenticated && (
        <>
          <div className="row m-2" id="loading-spinner">
            {loading && (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            )}
          </div>
          <form onSubmit={submitEvent}>
            <div className="row mb-2 align-items-center">
              <label htmlFor="eventCodeInput" className="col-4 col-sm-3 col-lg-2">
                Event Code:
              </label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  id="eventCodeInput"
                  placeholder="Event Code"
                  value={event?.event_id ?? ""}
                  name="event_id"
                  onChange={handleInputChange}
                  required
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row mb-2 align-items-center">
              <label htmlFor="eventNameInput" className="col-4 col-sm-3 col-lg-2">
                Name:
              </label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  id="eventNameInput"
                  placeholder="Event Name"
                  value={event?.event_name ?? ""}
                  name="event_name"
                  onChange={handleInputChange}
                  required
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row mb-2 align-items-center">
              <label htmlFor="minAgeFormControlInput" className="col-4 col-sm-3 col-lg-2">
                Min Age
              </label>
              <div className="col-8">
                <input
                  type="number"
                  className="form-control"
                  id="minAgeFormControlInput"
                  min="0"
                  name="age_lower"
                  placeholder=""
                  value={event?.age_lower ?? ""}
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row mb-2 align-items-center">
              <label htmlFor="maxAgeFormControlInput" className="col-4 col-sm-3 col-lg-2">
                Max Age
              </label>
              <div className="col-8">
                <input
                  type="number"
                  className="form-control"
                  id="maxAgeFormControlInput"
                  min="0"
                  name="age_upper"
                  placeholder=""
                  value={event?.age_upper ?? ""}
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row mb-2 align-items-center">
              <label htmlFor="eventDescription">Event Description</label>
            </div>
            <div className="row mb-2 align-items-center">
              <div className="col-12 col-sm-11 col-lg-10">
                <textarea
                  className="form-control"
                  id="eventDescription"
                  name="description"
                  placeholder="Event Description..."
                  aria-describedby="eventDescription"
                  value={event?.description ?? ""}
                  onChange={handleInputChange}
                  rows={3}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row my-2 align-items-center">
              <div className="col-4 col-sm-3 col-lg-2">Event Started?</div>
              <div className="col-8 col-md-6 col-lg-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={event?.event_started ?? false}
                  id="eventStartedCheck"
                  name="event_started"
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row my-2 align-items-center">
              <div className="col-4 col-sm-3 col-lg-2">Profiles Locked?</div>
              <div className="col-8 col-md-6 col-lg-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={event?.profile_locked ?? false}
                  id="eventProfileLockedCheck"
                  name="profile_locked"
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row my-2 align-items-center">
              <div className="col-4 col-sm-3 col-lg-2">Reveal Personal Details?</div>
              <div className="col-8 col-md-6 col-lg-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={event?.reveal_personal_details ?? false}
                  id="eventRevealPersonalDetailsCheck"
                  name="reveal_personal_details"
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row my-2 align-items-center">
              <div className="col-4 col-sm-3 col-lg-2">Email Approval Required?</div>
              <div className="col-8 col-md-6 col-lg-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={event?.require_email_approval ?? false}
                  id="requireEmailApprovalCheck"
                  name="require_email_approval"
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row my-2 align-items-center">
              <div className="col-4 col-sm-3 col-lg-2">Payment Required?</div>
              <div className="col-8 col-md-6 col-lg-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={event?.payment_required ?? false}
                  id="eventPaymentRequiredCheck"
                  name="payment_required"
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </div>
            </div>
            <div className="row mb-2 align-items-center">
              <label htmlFor="eventPriceIdInput" className="col-4 col-sm-3 col-lg-2">
                Stripe Price Id:
              </label>
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  id="eventPriceIdInput"
                  placeholder="Stripe Price Id"
                  value={event?.stripe_price_id ?? ""}
                  name="stripe_price_id"
                  onChange={handleInputChange}
                  disabled={loading || !event?.payment_required}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-11 col-lg-10">
                <div className="d-flex justify-content-between my-2">
                  <Link to="/Events" className="btn btn-primary">
                    Go Back
                  </Link>
                  <button type="submit" className="btn btn-success" disabled={loading}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default CreateEvent;
