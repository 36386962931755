interface UserRoleModel {
    user_id: string;
    role: string;
    event_code: string;
    payment_reference: string;
}

enum UserRoles {
    /// <summary>
    /// Users that have been assigned no role.
    /// </summary>
    Other = "Other",

    /// <summary>
    /// Users that have been assigned user role.
    /// </summary>
    User = "User",

    /// <summary>
    /// Users that have been assigned event owner role.
    /// </summary>
    EventOwner = "EventOwner",

    /// <summary>
    /// Users that have been assigned facilitator role.
    /// </summary>
    Facilitator = "Facilitator",

    /// <summary>
    /// Users that have been assigned admin role.
    /// </summary>
    Admin = "Admin",
}

export default UserRoleModel;
export { UserRoles };
