interface Props {
    children: React.ReactNode | React.ReactNode[];
}

function ContentContainer({ children }: Props) {
    return (
        <div className="container">
            <main role="main" className="pb-3">
                {children}
            </main>
        </div>
    );
}

export default ContentContainer;
