import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AzureFunctionUri, checkUserRole } from "./Constants";
import EventModel from "./models/EventModel";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";
import { AlertType } from "./AlertMessage";

interface Props {
  userRole: UserRoleModel;
  setAlertMessage: Function;
}
function Events({ userRole, setAlertMessage }: Props) {
  const [loading, setLoading] = useState(true);
  const [events, setEvents] = useState([] as EventModel[]);
  const [viewingEvent, setViewingEvent] = useState<EventModel | undefined>(undefined);
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const isMountedRef = useRef(true);

  const getEvents = useCallback(async () => {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    if (!checkUserRole(userRole, [UserRoles.Admin, UserRoles.EventOwner])) return;

    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    fetch(AzureFunctionUri + "api/Events", {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. Events (GET: Events): ${response.statusText}(${response.status})`
          );
        }
        return response.json();
      })
      .then((data: EventModel[]) => {
        if (isMountedRef.current) {
          setEvents(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        if (isMountedRef.current) setLoading(false);
      });
  }, [getAccessTokenSilently, isAuthenticated, isLoading, userRole]);

  async function fetchUserProfilesCsv(eventId: string | undefined) {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    if (viewingEvent == null || viewingEvent === undefined) return;

    fetch(AzureFunctionUri + "api/UserProfilesDownload/" + encodeURIComponent(viewingEvent.event_id), {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. Events (GET: UserProfilesDownload): ${response.statusText}(${response.status})`
          );
        }

        return response.blob();
      })
      .then((blob) => {
        if (isMountedRef.current) {
          setLoading(false);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `UserProfiles_${viewingEvent.event_id}.csv`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        }
      })
      .catch((error) => {
        console.error(error);
        if (isMountedRef.current) {
          setLoading(false);
          setAlertMessage({
            message: "An error occurred while downloading user profiles.",
            messageType: AlertType.ErrorMessage,
          });
          setViewingEvent(undefined);
        }
      });
  }

  async function fetchUserDecisionsCsv(eventId: string | undefined) {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    if (viewingEvent == null || viewingEvent === undefined) return;

    fetch(AzureFunctionUri + "api/UserDecisionsDownload/" + encodeURIComponent(viewingEvent.event_id), {
      method: "GET",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. Events (GET: UserDecisionsDownload): ${response.statusText}(${response.status})`
          );
        }

        return response.blob();
      })
      .then((blob) => {
        if (isMountedRef.current) {
          setLoading(false);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = `UserDecisions_${viewingEvent.event_id}.csv`;
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();
          a.remove(); //afterwards we remove the element again
        }
      })
      .catch((error) => {
        console.error(error);
        if (isMountedRef.current) {
          setLoading(false);
          setAlertMessage({
            message: "An error occurred while downloading user decisions.",
            messageType: AlertType.ErrorMessage,
          });
          setViewingEvent(undefined);
        }
      });
  }

  async function deleteEvent(eventId: string | undefined) {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    if (viewingEvent == null || viewingEvent === undefined) return;
    fetch(AzureFunctionUri + "api/Events/" + encodeURIComponent(viewingEvent.event_id), {
      method: "DELETE",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. Events (DELETE: Events): ${response.statusText}(${response.status})`
          );
        }

        if (isMountedRef.current) {
          setLoading(false);
          setAlertMessage({
            message: "Successfully deleted event. Please refresh the page.",
            messageType: AlertType.SuccessMessage,
          });
          getEvents();
          setShowDeleteEventModal(false);
          setViewingEvent(undefined);
        }
      })
      .catch((error) => {
        console.error(error);
        if (isMountedRef.current) {
          setLoading(false);
          setAlertMessage({
            message: "An error occurred while deleting event.",
            messageType: AlertType.ErrorMessage,
          });
          setShowDeleteEventModal(false);
          setViewingEvent(undefined);
        }
      });
  }

  useEffect(() => {
    getEvents();

    return () => {
      isMountedRef.current = false;
    };
  }, [getEvents]);

  return (
    <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
      <div className="pt-2">
        <h1 className="d-inline">Events</h1>
        {!isLoading && isAuthenticated && userRole.role === UserRoles.Admin && (
          <div className="d-inline float-end mx-2">
            <Link to="/Events/Create" className="btn btn-success">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="currentColor"
                className="bi bi-plus-circle align-middle"
                viewBox="0 0 16 16"
              >
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </Link>
          </div>
        )}
      </div>
      <br />
      <div>
        {!loading && events && events.length > 0 && (
          <div className="row">
            {events.map((event, index: number) => {
              return (
                <div className="col-xs-12 col-sm-6 col-lg-4 mb-2" key={index}>
                  <div className="card">
                    <div className="card-header">{event.event_name}</div>
                    <div className="card-body">
                      <h5 className="card-title">Code: {event.event_id}</h5>
                      <p className="card-text">
                        <b>Event Started:</b> {event?.event_started ? " true" : " false"} <br />
                        <b>Profiles Locked:</b> {event.profile_locked ? " true" : " false"} <br />
                        <b>Personal Details Revealed:</b> {event.reveal_personal_details ? " true" : " false"} <br />
                        <b>Registered Users:</b> {event.registered_users} <br />
                        <b>Approved Users:</b> {event.approved_users} <br />
                        <b>Male Profiles:</b> {event.male_profiles} <br />
                        <b>Female Profiles:</b> {event.female_profiles}
                      </p>
                    </div>
                    <div className="card-footer text-body-secondary">
                      <button className="btn btn-sm btn-primary float-end" onClick={() => setViewingEvent(event)}>
                        View
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      <Modal
        centered={true}
        show={viewingEvent !== undefined}
        onHide={() => setViewingEvent(undefined)}
        aria-labelledby="viewingEventModal"
        aria-hidden="true"
        tabIndex={-1}
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title id="eventModalLabel">Event Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-1">
            <div>
              <b>Event Code:</b> {viewingEvent?.event_id}
            </div>
            <div>
              <b>Name:</b> {viewingEvent?.event_name}
            </div>
            <div>
              <b>Age Restriction:</b> {viewingEvent?.age_lower} - {viewingEvent?.age_upper}
            </div>
            <div>
              <b>Event Started:</b> {viewingEvent?.event_started ? " true" : " false"}
            </div>
            <div>
              <b>Profiles Locked:</b> {viewingEvent?.profile_locked ? " true" : " false"}
            </div>
            <div>
              <b>Personal Details Revealed:</b> {viewingEvent?.reveal_personal_details ? " true" : " false"}
            </div>
            <div>
              <b>Payment Required:</b> {viewingEvent?.payment_required ? " true" : " false"}
            </div>
            <div>
              <b>Email Approval Required:</b> {viewingEvent?.require_email_approval ? " true" : " false"}
            </div>
            <br />
            <div>
              {checkUserRole(userRole, [UserRoles.Admin, UserRoles.EventOwner]) && (
                <>
                  {viewingEvent?.require_email_approval && (
                    <>
                      <div>Manage Users:</div>
                      <div className="mb-3">
                        <Link
                          to={"/EventUsers?id=" + viewingEvent?.event_id}
                          className="btn btn-primary"
                          id={"eventUsers_" + viewingEvent?.event_id}
                        >
                          Manage
                        </Link>
                      </div>
                    </>
                  )}
                  <div>Download User Profiles:</div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={async () => await fetchUserProfilesCsv(viewingEvent?.event_id)}
                      disabled={isLoading}
                    >
                      Download
                    </button>
                  </div>
                  <div>Download User Decisions:</div>
                  <div className="mb-3">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={async () => await fetchUserDecisionsCsv(viewingEvent?.event_id)}
                      disabled={isLoading}
                    >
                      Download
                    </button>
                  </div>
                </>
              )}
            </div>
            <div>
              {checkUserRole(userRole, [UserRoles.Admin]) && (
                <>
                  <div>
                    <b>Delete this Event:</b>
                  </div>
                  <div className="mb-3">
                    <div>
                      Deleting this event will delete all event data from the system. This action is irreversible.
                    </div>
                    <div>
                      <button className="btn btn-danger" type="button" onClick={() => setShowDeleteEventModal(true)}>
                        Delete
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {viewingEvent?.event_id && (
            <Link
              to={"/Events/Edit?id=" + viewingEvent.event_id}
              className="btn btn-primary"
              id={"eventEdit_" + viewingEvent.event_id}
            >
              Edit
            </Link>
          )}
          <button type="button" className="btn btn-secondary" onClick={() => setViewingEvent(undefined)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        centered={true}
        show={showDeleteEventModal}
        onHide={() => setShowDeleteEventModal(false)}
        aria-labelledby="deleteEventModal"
      >
        <Modal.Header className="py-2" closeButton>
          <Modal.Title id="deleteEventModalLabel">Delete Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="my-1">
            Are you sure you want to delete this Event? <br />
            Event Id: {viewingEvent?.event_id} <br />
            Name: {viewingEvent?.event_name} <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={() => setShowDeleteEventModal(false)}>
            Cancel
          </button>
          <button
            className="btn btn-danger"
            onClick={() => {
              deleteEvent(viewingEvent?.event_id);
              setShowDeleteEventModal(false);
            }}
            disabled={viewingEvent?.event_id ? false : true}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Events;
