import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";

function Auth0Wrapper() {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";
    const scope = process.env.REACT_APP_AUTH0_SCOPE ?? "";

    return (
        <Auth0Provider domain={domain} clientId={clientId} redirectUri={window.location.origin} useRefreshTokens={true} audience={audience} scope={scope}>
            <App />
        </Auth0Provider>
    );
}

export default Auth0Wrapper;
