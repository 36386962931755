import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AlertType } from "./AlertMessage";
import { AzureFunctionUri } from "./Constants";
import { UserDecisionModel } from "./models/UserDecisionModel";
import UserProfileModel, { GenderEnum } from "./models/UserProfileModel";
import UserRoleModel from "./models/UserRoleModel";
import UserProfileModal, { UserDecisionEnum } from "./UserProfileModal";
import { Form } from "react-bootstrap";

interface Props {
  userRole: UserRoleModel;
  loadingUserRole: boolean;
  setAlertMessage: Function;
}

function UserProfiles({ userRole, loadingUserRole, setAlertMessage }: Props) {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [defaultUserProfiles, setDefaultUserProfiles] = useState([] as UserProfileModel[]);
  const [userProfiles, setUserProfiles] = useState(defaultUserProfiles as UserProfileModel[]);
  const [incomingDecisions, setIncomingDecisions] = useState([] as UserDecisionModel[]);
  const [outgoingDecisions, setOutgoingDecisions] = useState([] as UserDecisionModel[]);
  const [viewingUserProfile, setViewingUserProfile] = useState<undefined | UserProfileModel>(undefined);

  const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const isMountedRef = useRef(true);
  const isMountedIncomingDecisionsRef = useRef(true);
  const isMountedOutgoingDecisionsRef = useRef(true);
  const history = useHistory();

  function getIncomingDecisionForUser(userId: string) {
    var decision = incomingDecisions.find((d) => d.user_id === userId);
    if (decision) {
      return decision.decision ? UserDecisionEnum.Interested : UserDecisionEnum.NotInterested;
    }
    return UserDecisionEnum.Neither;
  }

  function getOutgoingDecisionForUser(userId: string) {
    var decision = outgoingDecisions.find((d) => d.for_user === userId);
    if (decision) {
      return decision.decision ? UserDecisionEnum.Interested : UserDecisionEnum.NotInterested;
    }
    return UserDecisionEnum.Neither;
  }

  function handleSearch(e: any) {
    e.preventDefault();
    const target = e.target;
    const value: string = target.value;

    setSearch(value);

    if (value === "") {
      setUserProfiles(defaultUserProfiles);
      return;
    }

    const filteredProfiles = defaultUserProfiles.filter((userProfile) => {
      const fullName = `${userProfile.first_name} ${userProfile.last_name}`;
      return fullName.toLowerCase().match(value.toLowerCase());
    });

    setUserProfiles(filteredProfiles);
  }

  const fetchUserProfiles = useCallback(async () => {
    if (isLoading || (!isLoading && !isAuthenticated)) return;

    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    fetch(AzureFunctionUri + "api/UserProfiles/" + userRole.event_code + "/" + encodeURIComponent(user?.sub ?? ""), {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserProfiles (GET: UserProfiles): ${response.statusText}(${response.status})`
          );
        }
        if (response.status === 204) {
          setLoading(false);
          setAlertMessage({
            message: "Please complete your user profile first",
            messageType: AlertType.InfoMessage,
          });
          if (isMountedRef) {
            history.push("/UserProfile");
          }
        }
        return response.json();
      })
      .then((data: UserProfileModel[]) => {
        if (isMountedRef.current && data) {
          setDefaultUserProfiles(data);
          setUserProfiles(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
      });
  }, [isLoading, isAuthenticated, getAccessTokenSilently, userRole.event_code, user?.sub, setAlertMessage, history]);

  const fetchIncomingDecisions = useCallback(async () => {
    if (isLoading || (!isLoading && !isAuthenticated)) return;

    isMountedIncomingDecisionsRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    fetch(AzureFunctionUri + "api/UserDecisionsIncoming/" + encodeURIComponent(user?.sub ?? ""), {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserProfiles (GET: UserDecisionsIncoming): ${response.statusText}(${response.status})`
          );
        }
        return response.json();
      })
      .then((data: UserDecisionModel[]) => {
        if (isMountedIncomingDecisionsRef.current && data) {
          setIncomingDecisions(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
      });
  }, [getAccessTokenSilently, isAuthenticated, isLoading, user?.sub]);

  const fetchOutgoingDecisions = useCallback(async () => {
    if (isLoading || (!isLoading && !isAuthenticated)) return;

    isMountedOutgoingDecisionsRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    fetch(AzureFunctionUri + "api/UserDecisionsOutgoing/" + encodeURIComponent(user?.sub ?? ""), {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserProfiles (GET: UserDecisionsOutgoing): ${response.statusText}(${response.status})`
          );
        }
        return response.json();
      })
      .then((data: UserDecisionModel[]) => {
        if (isMountedOutgoingDecisionsRef.current && data) {
          setOutgoingDecisions(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
      });
  }, [getAccessTokenSilently, isAuthenticated, isLoading, user?.sub]);

  useEffect(() => {
    if (!loadingUserRole) {
      if (userRole.event_code) {
        fetchUserProfiles();
      } else {
        setAlertMessage({
          message: "Please register for an event with an event code.",
          messageType: AlertType.InfoMessage,
        });
        if (isMountedRef) {
          history.push("/UserProfile");
        }
      }
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [fetchUserProfiles, history, loadingUserRole, setAlertMessage, userRole.event_code]);

  useEffect(() => {
    const fetchCall = fetchIncomingDecisions;

    const id = setInterval(() => fetchCall(), 20 * 1000);
    if (!loadingUserRole && userRole.event_code) {
      fetchCall();
    }

    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUserRole, userRole.event_code]);

  useEffect(() => {
    const fetchCall = fetchOutgoingDecisions;

    const id = setInterval(() => fetchCall(), 20 * 1000);
    if (!loadingUserRole && userRole.event_code) {
      fetchCall();
    }

    return () => {
      clearInterval(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingUserRole, userRole.event_code]);

  return (
    <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
      <h1>User Profiles</h1>
      <div style={{ visibility: loading ? "visible" : "hidden" }}>
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <div className="col-12">
        <Form
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <Form.Group className="mb-3">
            <Form.Control type="search" placeholder="Search..." value={search} onChange={handleSearch} />
          </Form.Group>
        </Form>
      </div>

      <table className="table">
        <thead>
          <tr>
            <th scope="col" className="col-7">
              Name
            </th>
            <th scope="col" className="col-2"></th>
            <th scope="col" className="col-3"></th>
          </tr>
        </thead>
        <tbody>
          {userProfiles &&
            userProfiles.map((userProfile: UserProfileModel, index: number) => {
              let incoming = getIncomingDecisionForUser(userProfile.user_id) === UserDecisionEnum.Interested;

              let outgoing = getOutgoingDecisionForUser(userProfile.user_id) === UserDecisionEnum.Interested;

              return (
                <tr key={index}>
                  <td>
                    <span
                      className={
                        "badge me-1" + (userProfile.gender === GenderEnum.Male ? " text-bg-primary" : " text-bg-danger")
                      }
                    >
                      {userProfile.friendly_user_id}
                    </span>
                    <span>{`${userProfile.first_name} ${userProfile.last_name}`}</span>
                  </td>
                  <td className="align-middle p-1">
                    <div className="d-flex justify-content-center">
                      {incoming && outgoing ? (
                        <span className="badge rounded-pill bg-success">Matched</span>
                      ) : (
                        <>
                          {incoming && <span className="badge rounded-pill bg-warning text-dark">Liked Me</span>}
                          {outgoing && <span className="badge rounded-pill bg-secondary">I Liked</span>}
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-primary float-end"
                      onClick={() => setViewingUserProfile(userProfile)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {viewingUserProfile && (
        <UserProfileModal
          userProfile={viewingUserProfile}
          incomingDecision={getIncomingDecisionForUser(viewingUserProfile.user_id)}
          outgoingDecision={getOutgoingDecisionForUser(viewingUserProfile.user_id)}
          fetchOutgoingDecisions={fetchOutgoingDecisions}
          closeModal={() => {
            setViewingUserProfile(undefined);
          }}
        />
      )}
    </div>
  );
}

export default UserProfiles;
