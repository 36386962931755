import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { AzureFunctionUri, checkUserRole } from "./Constants";
import { Auth0User } from "./models/Auth0User";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";
import ViewUserModal from "./ViewUserModal";
import { Form } from "react-bootstrap";

interface EventUsers {
    event_code: string;
    eventOwnersList: Auth0User[];
    facilitatorsList: Auth0User[];
    usersList: Auth0User[];
    newUsersList: Auth0User[];
}

interface Props {
    userRole: UserRoleModel;
}
function Users({ userRole }: Props) {
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [defaultAdminsList, setDefaultAdminsList] = useState([] as Auth0User[]);
    const [adminsList, setAdminsList] = useState(defaultAdminsList as Auth0User[]);
    const [defaultEventUsers, setDefaultEventUsers] = useState([] as EventUsers[]);
    const [eventUsers, setEventUsers] = useState(defaultEventUsers as EventUsers[]);
    const [viewingUser, setViewingUser] = useState<Auth0User | undefined>(undefined);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);

    function handleSearch(event: any) {
        event.preventDefault();
        const target = event.target;
        const value: string = target.value;

        setSearch(value);

        if (value === "") {
            setAdminsList(defaultAdminsList);
            setEventUsers(defaultEventUsers);
            return;
        }

        let filteredEventUsers = [] as EventUsers[];
        defaultEventUsers.forEach((event) => {
            let eventOwnersList = event.eventOwnersList.filter((eventOwner) => {
                return eventOwner.name.toLowerCase().match(value.toLowerCase());
            });
            let facilitatorsList = event.facilitatorsList.filter((facilitator) => {
                return facilitator.name.toLowerCase().match(value.toLowerCase());
            });
            let usersList = event.usersList.filter((user) => {
                return user.name.toLowerCase().match(value.toLowerCase());
            });
            let newUsersList = event.newUsersList.filter((user) => {
                return user.name.toLowerCase().match(value.toLowerCase());
            });

            filteredEventUsers.push({
                event_code: event.event_code,
                eventOwnersList: eventOwnersList,
                facilitatorsList: facilitatorsList,
                usersList: usersList,
                newUsersList: newUsersList,
            });
        });
        setEventUsers(filteredEventUsers);

        let filteredAdminList = defaultAdminsList.filter((admin) => {
            return admin.name.toLowerCase().match(value.toLowerCase());
        });
        setAdminsList(filteredAdminList);
    }

    const fetchUsers = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!checkUserRole(userRole, [UserRoles.EventOwner, UserRoles.Admin])) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/AllUsers", {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Users (GET: Users): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data: Auth0User[]) => {
                if (isMountedRef.current) {
                    setDefaultAdminsList(
                        data.filter((user: Auth0User) => {
                            return user.role === UserRoles.Admin;
                        })
                    );
                    setAdminsList(
                        data.filter((user: Auth0User) => {
                            return user.role === UserRoles.Admin;
                        })
                    );

                    var eventCodes: string[] = [];
                    data.forEach((u) => {
                        var userEventCode = u.event_code;
                        if (!userEventCode) {
                            userEventCode = "No_Event_Code";
                        }

                        if (userEventCode.includes(",")) {
                            var split = userEventCode.split(",");

                            split.forEach((eventCode) => {
                                if (!eventCodes.includes(eventCode)) {
                                    eventCodes.push(eventCode);
                                }
                            });
                        } else if (!eventCodes.includes(userEventCode)) {
                            eventCodes.push(userEventCode);
                        }
                    });

                    var eventUsers: EventUsers[] = [];
                    eventCodes.forEach((eventCode) => {
                        var eventCodeToFind = eventCode;
                        if (eventCodeToFind === "No_Event_Code") {
                            eventCodeToFind = "";
                        }
                        var eventOwners = data.filter((user: Auth0User) => {
                            return (
                                user.role === UserRoles.EventOwner &&
                                (user.event_code.includes(",") ? user.event_code.includes(eventCodeToFind) : user.event_code === eventCodeToFind)
                            );
                        });
                        var facilitators = data.filter((user: Auth0User) => {
                            return (
                                user.role === UserRoles.Facilitator &&
                                (user.event_code.includes(",") ? user.event_code.includes(eventCodeToFind) : user.event_code === eventCodeToFind)
                            );
                        });
                        var users = data.filter((user: Auth0User) => {
                            return user.role === UserRoles.User && user.event_code === eventCodeToFind;
                        });
                        var nonUsers = data.filter((user: Auth0User) => {
                            return (
                                user.role !== UserRoles.Admin &&
                                user.role !== UserRoles.EventOwner &&
                                user.role !== UserRoles.Facilitator &&
                                user.role !== UserRoles.User &&
                                user.event_code === eventCodeToFind
                            );
                        });

                        eventUsers.push({
                            event_code: eventCode,
                            eventOwnersList: eventOwners,
                            facilitatorsList: facilitators,
                            usersList: users,
                            newUsersList: nonUsers,
                        });
                    });

                    setDefaultEventUsers(eventUsers);
                    setEventUsers(eventUsers);
                    setSearch("");
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }, [getAccessTokenSilently, isAuthenticated, isLoading, userRole]);

    useEffect(() => {
        fetchUsers();

        return () => {
            isMountedRef.current = false;
        };
    }, [fetchUsers]);

    return (
        <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
            <h1>Users</h1>
            <div>
                <Form
                    onSubmit={(event) => {
                        event.preventDefault();
                    }}
                >
                    <Form.Group className="mb-3">
                        <Form.Control type="search" placeholder="Search..." value={search} onChange={handleSearch} />
                    </Form.Group>
                </Form>
            </div>
            <div>
                {!loading && adminsList && adminsList.length > 0 && (
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col" className="col-2"></th>
                                <th scope="col" className="col-7">
                                    Admins
                                </th>
                                <th scope="col" className="col-3"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {adminsList.map((user, index: number) => {
                                return (
                                    <tr key={index}>
                                        <th
                                            scope="row"
                                            style={{
                                                wordBreak: "break-all",
                                            }}
                                        >
                                            {index + 1}
                                        </th>
                                        <td
                                            style={{
                                                wordBreak: "break-all",
                                            }}
                                        >
                                            {user.name}
                                        </td>
                                        <td
                                            style={{
                                                wordBreak: "break-all",
                                            }}
                                        >
                                            <button className="btn btn-sm btn-primary float-end" onClick={() => setViewingUser(user)}>
                                                View
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
                {!loading && eventUsers && eventUsers.length > 0 && (
                    <>
                        {eventUsers.map((eventUsersObj: EventUsers, index1: number) => {
                            return (
                                <div key={index1}>
                                    {(eventUsersObj.eventOwnersList.length > 0 ||
                                        eventUsersObj.facilitatorsList.length > 0 ||
                                        eventUsersObj.usersList.length > 0 ||
                                        eventUsersObj.newUsersList.length > 0) && (
                                        <>
                                            <h4>{eventUsersObj.event_code}</h4>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="col-2"></th>
                                                        <th scope="col" className="col-7">
                                                            Event Owners ({eventUsersObj.event_code})
                                                        </th>
                                                        <th scope="col" className="col-3"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {eventUsersObj.eventOwnersList.map((user, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th
                                                                    scope="row"
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    {index + 1}
                                                                </th>
                                                                <td
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    {user.name}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    <button className="btn btn-sm btn-primary float-end" onClick={() => setViewingUser(user)}>
                                                                        View
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="col-2"></th>
                                                        <th scope="col" className="col-7">
                                                            Facilitators ({eventUsersObj.event_code})
                                                        </th>
                                                        <th scope="col" className="col-3"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {eventUsersObj.facilitatorsList.map((user, index: number) => {
                                                        return (
                                                            <tr key={index}>
                                                                <th
                                                                    scope="row"
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    {index + 1}
                                                                </th>
                                                                <td
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    {user.name}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    <button className="btn btn-sm btn-primary float-end" onClick={() => setViewingUser(user)}>
                                                                        View
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="col-2"></th>
                                                        <th scope="col" className="col-7">
                                                            Users ({eventUsersObj.event_code})
                                                        </th>
                                                        <th scope="col" className="col-3"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {eventUsersObj.usersList.map((user, index2: number) => {
                                                        return (
                                                            <tr key={index1 + "_" + index2}>
                                                                <th
                                                                    scope="row"
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    {index2 + 1}
                                                                </th>
                                                                <td
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    {user.name}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    <button className="btn btn-sm btn-primary float-end" onClick={() => setViewingUser(user)}>
                                                                        View
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" className="col-2"></th>
                                                        <th scope="col" className="col-7">
                                                            New Users ({eventUsersObj.event_code})
                                                        </th>
                                                        <th scope="col" className="col-3"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {eventUsersObj.newUsersList.map((user, index2: number) => {
                                                        return (
                                                            <tr key={index1 + "_" + index2}>
                                                                <th
                                                                    scope="row"
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    {index2 + 1}
                                                                </th>
                                                                <td
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    {user.name}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        wordBreak: "break-all",
                                                                    }}
                                                                >
                                                                    <button className="btn btn-sm btn-primary float-end" onClick={() => setViewingUser(user)}>
                                                                        View
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </>
                )}
                {loading && (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
                {viewingUser && (
                    <ViewUserModal
                        userRole={userRole}
                        auth0User={viewingUser}
                        closeModal={() => {
                            setViewingUser(undefined);
                        }}
                    />
                )}
            </div>
        </div>
    );
}

export default Users;
