import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import {
  AzureFunctionUri,
  countInterests,
  getEducationLevelFriendlyName,
  getInterestIcon,
  getRelationshipStatusFriendlyName,
  isValidHttpUrl,
  sortUserInterestsByMatches,
  sortUserInterestsByName,
} from "./Constants";
import UserInterestsModel, { UserModel } from "./models/UserInterestsModel";
import { ReactComponent as UserProfileIcon } from "./images/person-circle.svg";
import FacebookLogo from "./images/FacebookLogo.png";
import LinkedInLogo from "./images/LinkedInLogo.png";
import InstagramLogo from "./images/InstagramLogo.png";
import { AlertType } from "./AlertMessage";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";
import { GenderEnum, InterestsEnum } from "./models/UserProfileModel";
import { Link } from "react-router-dom";

interface EventUserInterests {
  eventCode: string;
  userInterestsMale: UserInterestsModel[];
  userInterestsFemale: UserInterestsModel[];
}

enum SortBy {
  Name = "Name",
  Matches = "Matches",
}

interface Props {
  userRole: UserRoleModel;
  loadingUserRole: boolean;
  setAlertMessage: Function;
  getUserRole: Function;
}

function UserInterests({ userRole, loadingUserRole, setAlertMessage, getUserRole }: Props) {
  const [loading, setLoading] = useState(true);

  const [sortBy, setSortBy] = useState(SortBy.Name);
  const [search, setSearch] = useState("");
  const [loadingRegisterEvent, setLoadingRegisterEvent] = useState(false);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [viewingUserProfile, setViewingUserProfile] = useState<UserInterestsModel | undefined>(undefined);
  const [showUserImageModal, setShowUserImageModal] = useState(false);
  const [viewingUserImage, setViewingUserImage] = useState<string | undefined>(undefined);
  const [userRoleLocal, setUserRoleLocal] = useState({ ...userRole });
  const [showInterestsModal, setShowInterestsModal] = useState(false);
  const [defaultEventUserInterests, setDefaultEventUserInterests] = useState([] as EventUserInterests[]);
  const [eventUserInterests, setEventUserInterests] = useState(defaultEventUserInterests as EventUserInterests[]);

  const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const isMountedRef = useRef(true);
  const isMountedRefProfileImage = useRef(false);
  const isMountedRefRegisterEvent = useRef(true);

  function handleEventCodeInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var newUserRole: any = { ...userRoleLocal };
    if (newUserRole) {
      newUserRole[name] = value;
      setUserRoleLocal(newUserRole);
    }
  }

  function handleSearch(event: any) {
    event.preventDefault();
    const target = event.target;
    const value: string = target.value;

    setSearch(value);

    if (value === "") {
      let userInterests = [] as EventUserInterests[];
      defaultEventUserInterests.forEach((eventUserInterest) => {
        let sortedUserInterestsMale = eventUserInterest.userInterestsMale;
        let sortedUserInterestsFemale = eventUserInterest.userInterestsFemale;

        if (sortBy === SortBy.Name) {
          sortedUserInterestsMale = sortUserInterestsByName(eventUserInterest.userInterestsMale);
          sortedUserInterestsFemale = sortUserInterestsByName(eventUserInterest.userInterestsFemale);
        }

        if (sortBy === SortBy.Matches) {
          sortedUserInterestsMale = sortUserInterestsByMatches(eventUserInterest.userInterestsMale);
          sortedUserInterestsFemale = sortUserInterestsByMatches(eventUserInterest.userInterestsFemale);
        }

        userInterests.push({
          eventCode: eventUserInterest.eventCode,
          userInterestsMale: sortedUserInterestsMale,
          userInterestsFemale: sortedUserInterestsFemale,
        });
      });
      setEventUserInterests(userInterests);
      return;
    }

    let filteredEventUserInterests = [] as EventUserInterests[];
    defaultEventUserInterests.forEach((eventUserInterest) => {
      let userInterestsMale = eventUserInterest.userInterestsMale.filter((userInterest) => {
        const fullName = `${userInterest.first_name} ${userInterest.last_name}`;
        return fullName.toLowerCase().match(value.toLowerCase());
      });

      let userInterestsFemale = eventUserInterest.userInterestsFemale.filter((userInterest) => {
        const fullName = `${userInterest.first_name} ${userInterest.last_name}`;
        return fullName.toLowerCase().match(value.toLowerCase());
      });

      // No need to sort by name as default is always sorted by name.
      if (sortBy === SortBy.Name) {
        userInterestsMale = sortUserInterestsByName(userInterestsMale);
        userInterestsFemale = sortUserInterestsByName(userInterestsFemale);
      }

      if (sortBy === SortBy.Matches) {
        userInterestsMale = sortUserInterestsByMatches(userInterestsMale);
        userInterestsFemale = sortUserInterestsByMatches(userInterestsFemale);
      }

      filteredEventUserInterests.push({
        eventCode: eventUserInterest.eventCode,
        userInterestsMale: userInterestsMale,
        userInterestsFemale: userInterestsFemale,
      });
    });
    setEventUserInterests(filteredEventUserInterests);
  }

  const fetchUserInterests = useCallback(async () => {
    if (isLoading || (!isLoading && !isAuthenticated)) return;

    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    fetch(AzureFunctionUri + "api/AllUserInterests/" + userRole.event_code, {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserInterests (GET: AllUserInterests): ${response.statusText}(${response.status})`
          );
        }
        return response.json();
      })
      .then((data: UserInterestsModel[]) => {
        if (isMountedRef.current && data) {
          var eventCodes: string[] = [];
          data.forEach((u) => {
            var userEventCode = u.event_id;
            if (!userEventCode) {
              userEventCode = "No_Event_Code";
            }

            if (!eventCodes.includes(userEventCode)) {
              eventCodes.push(userEventCode);
            }
          });

          var eventUsers: EventUserInterests[] = [];
          eventCodes.forEach((eventCode) => {
            var eventCodeToFind = eventCode;
            if (eventCodeToFind === "No_Event_Code") {
              eventCodeToFind = "";
            }

            var users = data.filter((user: UserInterestsModel) => {
              return user.event_id === eventCodeToFind;
            });

            var maleUsers = users.filter((user: UserInterestsModel) => {
              return user.gender === GenderEnum.Male;
            });

            var femaleUsers = users.filter((user: UserInterestsModel) => {
              return user.gender === GenderEnum.Female;
            });

            eventUsers.push({
              eventCode: eventCode,
              userInterestsMale: sortUserInterestsByName(maleUsers),
              userInterestsFemale: sortUserInterestsByName(femaleUsers),
            });
          });

          setDefaultEventUserInterests(eventUsers);
          setEventUserInterests(eventUsers);
          setSearch("");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
      });
  }, [getAccessTokenSilently, isAuthenticated, isLoading, userRole.event_code]);

  const fetchUserImage = useCallback(
    async (eventId: string, userId: string) => {
      if (isLoading || (!isLoading && !isAuthenticated)) return;
      isMountedRefProfileImage.current = true;

      const accessToken = await getAccessTokenSilently();

      fetch(`${AzureFunctionUri}api/UserProfileImage/${eventId}/${userId}`, {
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok. UserInterests (GET: UserProfileImage): ${response.statusText}(${response.status})`
            );
          }
          if (response.status === 204) {
            return undefined;
          }
          return response.blob();
        })
        .then((blob) => {
          if (isMountedRefProfileImage.current) {
            if (blob) {
              const imageUrl = URL.createObjectURL(blob);
              setViewingUserImage(imageUrl);
            }
            isMountedRefProfileImage.current = false;
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          isMountedRefProfileImage.current = false;
        });
    },
    [getAccessTokenSilently, isAuthenticated, isLoading]
  );

  async function registerForEvent(event: any) {
    event.preventDefault();

    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedRefRegisterEvent.current = true;
    setLoadingRegisterEvent(true);

    const accessToken = await getAccessTokenSilently();

    fetch(AzureFunctionUri + "api/RegisterEvent/" + userRoleLocal?.event_code + "/" + user?.email, {
      method: "POST",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    }).then((response) => {
      if (response.status === 404) {
        isMountedRefRegisterEvent.current = false;
        setAlertMessage({
          message:
            "An error occurred. You have either entered an invalid event code or the event admin has not yet processed your payment.",
          messageType: AlertType.ErrorMessage,
        });
        setLoadingRegisterEvent(false);
      } else if (!response.ok) {
        throw new Error(
          `Network response was not ok. UserInterests (POST: RegisterEvent): ${response.statusText}(${response.status})`
        );
      } else {
        isMountedRefRegisterEvent.current = false;
        setLoadingRegisterEvent(false);
        setAlertMessage({
          message: "Successfully registered for event.",
          messageType: AlertType.SuccessMessage,
        });
        getUserRole();
      }
    });
  }

  useEffect(() => {
    fetchUserInterests();

    return () => {
      isMountedRef.current = false;
    };
  }, [fetchUserInterests]);

  return (
    <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
      <h1>User Interests</h1>
      {!loadingUserRole && (
        <>
          {!userRole.event_code && (
            <form onSubmit={registerForEvent}>
              <div className="mb-3">
                <label htmlFor="eventCodeFormControlInput" className="form-label">
                  Event Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="eventCodeFormControlInput"
                  name="event_code"
                  placeholder=""
                  value={userRoleLocal.event_code ?? ""}
                  onChange={handleEventCodeInputChange}
                  disabled={loadingRegisterEvent}
                />
              </div>
              <div className="d-flex justify-content-end mb-3">
                <button type="submit" className="btn btn-success" disabled={loading || loadingUserRole}>
                  {loading && (
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  {!loading && <>Save</>}
                </button>
              </div>
            </form>
          )}
          {userRole.event_code && eventUserInterests && eventUserInterests.length > 0 && (
            <>
              <Row className="pe-3">
                <Col xs={12} sm={8} lg={9} className="pe-0">
                  <Form
                    onSubmit={(event) => {
                      event.preventDefault();
                    }}
                  >
                    <Form.Group className="mb-3">
                      <Form.Control type="search" placeholder="Search..." value={search} onChange={handleSearch} />
                    </Form.Group>
                  </Form>
                </Col>
                <Col xs={12} sm={4} lg={3} className="pe-0">
                  <div className="d-flex flex-row-reverse">
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                        Sort By: {sortBy}
                      </Dropdown.Toggle>

                      <Dropdown.Menu variant="secondary">
                        <Dropdown.Item
                          active={sortBy === SortBy.Name}
                          onClick={() => {
                            setSortBy(SortBy.Name);
                            var eventUsers: EventUserInterests[] = [];
                            eventUserInterests.forEach((eventUserInterest) => {
                              eventUsers.push({
                                eventCode: eventUserInterest.eventCode,
                                userInterestsMale: sortUserInterestsByName(eventUserInterest.userInterestsMale),
                                userInterestsFemale: sortUserInterestsByName(eventUserInterest.userInterestsFemale),
                              });
                            });
                            setEventUserInterests(eventUsers);
                          }}
                        >
                          {SortBy.Name}
                        </Dropdown.Item>
                        <Dropdown.Item
                          active={sortBy === SortBy.Matches}
                          onClick={() => {
                            setSortBy(SortBy.Matches);
                            var eventUsers: EventUserInterests[] = [];
                            eventUserInterests.forEach((eventUserInterest) => {
                              eventUsers.push({
                                eventCode: eventUserInterest.eventCode,
                                userInterestsMale: sortUserInterestsByMatches(eventUserInterest.userInterestsMale),
                                userInterestsFemale: sortUserInterestsByMatches(eventUserInterest.userInterestsFemale),
                              });
                            });
                            setEventUserInterests(eventUsers);
                          }}
                        >
                          {SortBy.Matches}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
              {eventUserInterests.map((eventUserInterest: EventUserInterests, index: number) => {
                return (
                  <div key={index} className="mb-2">
                    {eventUserInterest.userInterestsMale.length > 0 && (
                      <div className="mb-2">
                        <h4>{eventUserInterest.eventCode + " (Male)"}</h4>
                        <Accordion>
                          {eventUserInterest.userInterestsMale &&
                            eventUserInterest.userInterestsMale.map(
                              (userInterest: UserInterestsModel, index1: number) => {
                                return (
                                  <Accordion.Item eventKey={index1.toString()} key={index1}>
                                    <Accordion.Header>
                                      <div>
                                        <span className="badge text-bg-primary me-1">
                                          {userInterest.friendly_user_id}
                                        </span>
                                        {`${userInterest.first_name} ${userInterest.last_name} (${
                                          userInterest.mutual_interests ? userInterest.mutual_interests.length : 0
                                        }|${
                                          userInterest.incoming_interests ? userInterest.incoming_interests.length : 0
                                        }|${
                                          userInterest.outgoing_interests ? userInterest.outgoing_interests.length : 0
                                        })`}
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="p-0">
                                      <div className="row my-2 mx-1">
                                        <div className="d-flex justify-content-center">
                                          <div
                                            className="btn btn-primary"
                                            onClick={() => {
                                              setViewingUserProfile(userInterest);
                                              setShowUserProfileModal(true);
                                              fetchUserImage(userInterest.event_id, userInterest.user_id);
                                            }}
                                          >
                                            View Profile
                                          </div>
                                          {(userRoleLocal.role === UserRoles.Admin ||
                                            userRoleLocal.role === UserRoles.EventOwner) && (
                                            <Link
                                              to={"/UserInterests/Edit?id=" + userInterest.user_id + "&eventCode="+ userInterest.event_id}
                                              className="btn btn-primary ms-2"
                                              id={"userInterestsEdit_" + userInterest.user_id}
                                            >
                                              Edit
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col" className="table-success">
                                              Mutual
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {userInterest.mutual_interests &&
                                            userInterest.mutual_interests.map(
                                              (mutualInterest: UserModel, index2: number) => {
                                                return (
                                                  <tr className="table-success" key={`${index1}${index2}`}>
                                                    <td>{`${mutualInterest.first_name} ${mutualInterest.last_name}`}</td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col" className="table-primary">
                                              Incoming
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {userInterest.incoming_interests &&
                                            userInterest.incoming_interests.map(
                                              (incomingInterest: UserModel, index2: number) => {
                                                return (
                                                  <tr className="table-primary" key={`${index1}${index2}`}>
                                                    <td>{`${incomingInterest.first_name} ${incomingInterest.last_name}`}</td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col" className="table-warning">
                                              Outgoing
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {userInterest.outgoing_interests &&
                                            userInterest.outgoing_interests.map(
                                              (outgoingInterest: UserModel, index2: number) => {
                                                return (
                                                  <tr className="table-warning" key={`${index1}${index2}`}>
                                                    <td>{`${outgoingInterest.first_name} ${outgoingInterest.last_name}`}</td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              }
                            )}
                        </Accordion>
                      </div>
                    )}
                    {eventUserInterest.userInterestsFemale.length > 0 && (
                      <div className="mb-2">
                        <h4>{eventUserInterest.eventCode + " (Female)"}</h4>
                        <Accordion>
                          {eventUserInterest.userInterestsFemale &&
                            eventUserInterest.userInterestsFemale.map(
                              (userInterest: UserInterestsModel, index1: number) => {
                                return (
                                  <Accordion.Item eventKey={index1.toString()} key={index1}>
                                    <Accordion.Header>
                                      <div>
                                        <span className="badge text-bg-danger me-1">
                                          {userInterest.friendly_user_id}
                                        </span>
                                        {`${userInterest.first_name} ${userInterest.last_name} (${
                                          userInterest.mutual_interests ? userInterest.mutual_interests.length : 0
                                        }|${
                                          userInterest.incoming_interests ? userInterest.incoming_interests.length : 0
                                        }|${
                                          userInterest.outgoing_interests ? userInterest.outgoing_interests.length : 0
                                        })`}
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="p-0">
                                      <div className="row my-2 mx-1">
                                        <div className="d-flex justify-content-center">
                                          <div
                                            className="btn btn-primary"
                                            onClick={() => {
                                              setViewingUserProfile(userInterest);
                                              setShowUserProfileModal(true);
                                              fetchUserImage(userInterest.event_id, userInterest.user_id);
                                            }}
                                          >
                                            View Profile
                                          </div>
                                          {(userRoleLocal.role === UserRoles.Admin ||
                                            userRoleLocal.role === UserRoles.EventOwner) && (
                                            <Link
                                              to={"/UserInterests/Edit?id=" + userInterest.user_id + "&eventCode="+ userInterest.event_id}
                                              className="btn btn-primary ms-2"
                                              id={"userInterestsEdit_" + userInterest.user_id}
                                            >
                                              Edit
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col" className="table-success">
                                              Mutual
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {userInterest.mutual_interests &&
                                            userInterest.mutual_interests.map(
                                              (mutualInterest: UserModel, index2: number) => {
                                                return (
                                                  <tr className="table-success" key={`${index1}${index2}`}>
                                                    <td>{`${mutualInterest.first_name} ${mutualInterest.last_name}`}</td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col" className="table-primary">
                                              Incoming
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {userInterest.incoming_interests &&
                                            userInterest.incoming_interests.map(
                                              (incomingInterest: UserModel, index2: number) => {
                                                return (
                                                  <tr className="table-primary" key={`${index1}${index2}`}>
                                                    <td>{`${incomingInterest.first_name} ${incomingInterest.last_name}`}</td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th scope="col" className="table-warning">
                                              Outgoing
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {userInterest.outgoing_interests &&
                                            userInterest.outgoing_interests.map(
                                              (outgoingInterest: UserModel, index2: number) => {
                                                return (
                                                  <tr className="table-warning" key={`${index1}${index2}`}>
                                                    <td>{`${outgoingInterest.first_name} ${outgoingInterest.last_name}`}</td>
                                                  </tr>
                                                );
                                              }
                                            )}
                                        </tbody>
                                      </table>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                );
                              }
                            )}
                        </Accordion>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          )}
        </>
      )}
      {viewingUserProfile && (
        <>
          <Modal show={showUserImageModal} onHide={() => setShowUserImageModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Profile Picture</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex align-items-center p-1">
              {viewingUserImage && (
                <div className="d-flex justify-content-center">
                  <img src={viewingUserImage} className="img-fluid" alt="User Profile Pic" />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  setShowUserImageModal(false);
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showUserProfileModal}
            centered
            onHide={() => {
              setShowUserProfileModal(false);
            }}
            onExited={() => {
              isMountedRefProfileImage.current = false;
              setShowUserImageModal(false);
              setViewingUserImage(undefined);
              setViewingUserProfile(undefined);
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>{`${viewingUserProfile?.first_name} ${viewingUserProfile?.last_name}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {viewingUserImage && (
                <>
                  <div className="d-flex justify-content-center">
                    <span className="border border-3 rounded-3 p-1 mb-3">
                      <img
                        src={viewingUserImage}
                        className="img-fluid"
                        alt="User Profile Pic"
                        style={{ maxHeight: "200px" }}
                        onClick={() => {
                          setShowUserImageModal(true);
                        }}
                      />
                    </span>
                  </div>
                </>
              )}
              {!viewingUserImage && (
                <div className="d-flex justify-content-center">
                  <span className="border border-3 rounded-3 p-1 mb-3">
                    <svg
                      className="bd-placeholder-img card-img-top"
                      width="150"
                      height="150"
                      xmlns="http://www.w3.org/2000/svg"
                      role="img"
                      aria-label="Placeholder"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                    >
                      <title>Placeholder</title>
                      <rect width="100%" height="100%" fill="#868e96"></rect>
                      <UserProfileIcon className="m-0" width="50" height="50" fill="white" x={50} y={50} />
                    </svg>
                  </span>
                </div>
              )}
              <table className="table">
                <tbody>
                  <tr>
                    <td>Event Code</td>
                    <td className="text-end">{viewingUserProfile?.event_id ?? ""}</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td className="text-end">{viewingUserProfile?.role ?? ""}</td>
                  </tr>
                  <tr>
                    <td>User Id</td>
                    <td className="text-end">
                      <span
                        className={
                          "badge" +
                          (viewingUserProfile.friendly_user_id
                            ? viewingUserProfile.gender === GenderEnum.Male
                              ? " text-bg-primary"
                              : " text-bg-danger"
                            : " text-bg-secondary")
                        }
                      >
                        {viewingUserProfile.friendly_user_id ?? "N/A"}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {viewingUserProfile?.email ?? ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {viewingUserProfile?.first_name + " " + viewingUserProfile?.last_name}
                    </td>
                  </tr>
                  <tr>
                    <td>Age</td>
                    <td className="text-end">{viewingUserProfile?.age}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td className="text-end">{viewingUserProfile?.gender}</td>
                  </tr>
                  <tr>
                    <td>City</td>
                    <td className="text-end">{viewingUserProfile?.city}</td>
                  </tr>
                  <tr>
                    <td>Citizenship</td>
                    <td className="text-end">{viewingUserProfile?.citizenship}</td>
                  </tr>
                  <tr>
                    <td>Height</td>
                    <td className="text-end">
                      {viewingUserProfile.height_value
                        ? `${viewingUserProfile.height_value}` +
                          (viewingUserProfile.height_unit ? ` ${viewingUserProfile.height_unit}` : "")
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Willing to relocate?</td>
                    <td className="text-end">{viewingUserProfile?.relocation ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>
                      {viewingUserProfile?.gender === GenderEnum.Male ? "Expects to wear hijab?" : "Wears hijab?"}
                    </td>
                    <td className="text-end">{viewingUserProfile?.hijab ? "Yes" : "No"}</td>
                  </tr>
                  <tr>
                    <td>Education</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {viewingUserProfile?.education && getEducationLevelFriendlyName(viewingUserProfile.education)}
                      {!viewingUserProfile?.education && ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Profession</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {viewingUserProfile?.profession ?? ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Facebook</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {viewingUserProfile.facebook_url && (
                        <>
                          {isValidHttpUrl(viewingUserProfile.facebook_url) ? (
                            <a href={viewingUserProfile?.facebook_url} target={"_blank"} rel="noreferrer">
                              <img src={FacebookLogo} alt="Facebook Logo" width={30} height={30} />
                            </a>
                          ) : (
                            <>{viewingUserProfile.facebook_url}</>
                          )}
                        </>
                      )}
                      {!viewingUserProfile?.facebook_url && <span>N/A</span>}
                    </td>
                  </tr>
                  <tr>
                    <td>LinkedIn</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {viewingUserProfile.linkedIn_url && (
                        <>
                          {isValidHttpUrl(viewingUserProfile.linkedIn_url) ? (
                            <a href={viewingUserProfile?.linkedIn_url} target={"_blank"} rel="noreferrer">
                              <img src={LinkedInLogo} alt="LinkedIn Logo" width={35} height={30} />
                            </a>
                          ) : (
                            <>{viewingUserProfile.linkedIn_url}</>
                          )}
                        </>
                      )}
                      {!viewingUserProfile?.linkedIn_url && <span>N/A</span>}
                    </td>
                  </tr>
                  <tr>
                    <td>Instagram</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {viewingUserProfile.instagram_url && (
                        <>
                          {isValidHttpUrl(viewingUserProfile.instagram_url) ? (
                            <a href={viewingUserProfile?.instagram_url} target={"_blank"} rel="noreferrer">
                              <img src={InstagramLogo} alt="Instagram Logo" width={30} height={30} />
                            </a>
                          ) : (
                            <>{viewingUserProfile.instagram_url}</>
                          )}
                        </>
                      )}
                      {!viewingUserProfile?.instagram_url && <span>N/A</span>}
                    </td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td className="text-end">
                      {viewingUserProfile?.phone_number && <span>{viewingUserProfile?.phone_number}</span>}
                      {!viewingUserProfile?.phone_number && <span>N/A</span>}
                    </td>
                  </tr>
                  <tr>
                    <td>Relationship Status</td>
                    <td className="text-end">
                      {viewingUserProfile?.relationship_status &&
                        getRelationshipStatusFriendlyName(viewingUserProfile?.relationship_status)}
                      {!viewingUserProfile?.relationship_status && ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="align-middle">Hobbies</td>
                    <td className="text-end">
                      <button className="btn btn-primary" type="button" onClick={() => setShowInterestsModal(true)}>
                        {countInterests(viewingUserProfile.interests) + " selected"}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Modal.Body>
            <Modal.Footer>
              {(userRoleLocal.role === UserRoles.Admin || userRoleLocal.role === UserRoles.EventOwner) && (
                <Link
                  to={"/UserInterests/Edit?id=" + viewingUserProfile.user_id + "&eventCode="+ viewingUserProfile.event_id}
                  className="btn btn-primary"
                  id={"userInterestsEdit_" + viewingUserProfile.user_id}
                >
                  Edit
                </Link>
              )}
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  setShowUserProfileModal(false);
                }}
              >
                Close
              </button>
            </Modal.Footer>
          </Modal>
          <Modal show={showInterestsModal} onHide={() => setShowInterestsModal(false)} fullscreen="md-down">
            <Modal.Header closeButton>
              <Modal.Title>Hobbies</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                {Object.keys(InterestsEnum).map((key, index) => {
                  return (
                    <div className="col-11 col-sm-3 col-md-4 col-xl-4 d-flex m-2" key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={key}
                          id={"flexCheck" + index}
                          checked={viewingUserProfile?.interests?.includes(key)}
                          disabled
                        />
                        <label className="form-check-label" htmlFor={"flexCheck" + index}>
                          <div>
                            <div className="d-inline">{getInterestIcon(key as InterestsEnum)}</div>
                            <div className="d-inline ms-1 align-middle">{key}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowInterestsModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {(loading || loadingUserRole) && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserInterests;
