import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { AlertType } from "./AlertMessage";
import { AzureFunctionUri } from "./Constants";

interface Props {
    setAlertMessage: Function;
}

function Profile({ setAlertMessage }: Props) {
    const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [defaultName, setDefaultName] = useState("");
    const [showPasswordPopover, setShowPasswordPopover] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    function handleNameChange(event: any) {
        setName(event.target.value);
    }

    async function updateName(event: any) {
        event.preventDefault();
        isMountedRef.current = true;
        setLoading(true);

        if (!isLoading && !isAuthenticated) return;
        if (!user?.sub) return;

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Auth0Profile/" + encodeURIComponent(user?.sub), {
            method: "PATCH",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: JSON.stringify({
                name: name,
            }),
        })
            .then(async (response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Profile (PATCH: Auth0Profile): " + response.statusText + "(" + response.status + ")");
                }
                if (isMountedRef.current) {
                    await getAccessTokenSilently({ ignoreCache: true }).then(() => {
                        if (isMountedRef.current) setDefaultName(user?.name ?? "");
                    });
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }

    async function resendVerificationEmail() {
        isMountedRef.current = true;
        setEmailSent(true);

        if (!isLoading && !isAuthenticated) return;
        if (!user?.sub) return;

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Auth0SendEmail/" + encodeURIComponent(user?.sub), {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Profile (POST: Auth0SendEmail): " + response.statusText + "(" + response.status + ")");
                }
                if (isMountedRef.current) {
                    setAlertMessage({
                        message: "Successfully sent verification email. Please verify your account then re-login.",
                        messageType: AlertType.InfoMessage,
                    });
                    window.scrollTo(0, 0);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) {
                    setAlertMessage({
                        message: "Error sending verification email. Please contact an admin.",
                        messageType: AlertType.ErrorMessage,
                    });
                    window.scrollTo(0, 0);
                }
            });
    }

    const passwordPopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Change Password?</Popover.Header>
            <Popover.Body>
                To change your password, click on <strong>forgot your password</strong> in the Log in page (Your email must be verified to do so)
            </Popover.Body>
        </Popover>
    );

    useEffect(() => {
        setName(user?.name ?? "");
        setDefaultName(user?.name ?? "");

        return () => {
            isMountedRef.current = false;
        };
    }, [setName, user]);

    return (
        <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol id="person-circle" viewBox="0 0 16 16">
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                        fillRule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                </symbol>
            </svg>
            <h1>Profile</h1>
            <>
                <div className="d-flex justify-content-center">
                    <div className="col-12">
                        <div>
                            {user?.picture && (
                                <div className="d-flex justify-content-center">
                                    <svg className="bi me-2" width="120" height="120" fill="grey">
                                        <use xlinkHref="#person-circle" />
                                    </svg>
                                </div>
                            )}
                        </div>
                        <form onSubmit={updateName}>
                            <div className="mb-3">
                                <label htmlFor="emailInput" className="form-label">
                                    <b>
                                        Email address ({user?.email_verified ? "" : "Not "}
                                        Verified)
                                    </b>
                                </label>
                                <div className="row">
                                    <div className={`col-12${user?.email_verified ? "" : " col-md-9"}`}>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="emailInput"
                                            placeholder="name@comm.com"
                                            value={user?.email ?? ""}
                                            readOnly
                                        />
                                    </div>
                                    {!user?.email_verified && (
                                        <div className="col-12 col-md-3">
                                            <button
                                                className="btn btn-primary float-end mt-1 mt-md-0"
                                                type="button"
                                                onClick={resendVerificationEmail}
                                                disabled={emailSent}
                                            >
                                                Resend Email
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="nameInput" className="form-label">
                                    Name
                                </label>
                                <input
                                    type="name"
                                    className="form-control"
                                    id="nameInput"
                                    placeholder="name"
                                    value={name}
                                    onChange={handleNameChange}
                                    disabled={!user?.email_verified}
                                />
                            </div>
                            <div className="mb-3">
                                <span className="me-2">Password</span>
                                <OverlayTrigger
                                    trigger="click"
                                    overlay={passwordPopover}
                                    show={showPasswordPopover}
                                    onToggle={() => {
                                        setShowPasswordPopover(!showPasswordPopover);
                                    }}
                                    rootClose
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        fill="currentColor"
                                        className="bi bi-info-circle-fill align-text-bottom"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                    </svg>
                                </OverlayTrigger>
                            </div>
                            <button type="submit" className="btn btn-success float-end" disabled={name === defaultName || loading}>
                                {loading && (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                                {!loading && <>Save</>}
                            </button>
                        </form>
                    </div>
                </div>
            </>
        </div>
    );
}

export default Profile;
