import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import UserInterestsModel from "./models/UserInterestsModel";
import { EducationEnum, InterestsEnum, RelationshipStatusEnum } from "./models/UserProfileModel";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";
import { ReloadNotificationId } from "./ReloadNotification";
import { ReactComponent as Travel } from "./images/interests/Travel.svg";
import { ReactComponent as Sports } from "./images/interests/Sports.svg";
import { ReactComponent as Films } from "./images/interests/Films.svg";
import { ReactComponent as BoardGames } from "./images/interests/BoardGames.svg";
import { ReactComponent as Arts } from "./images/interests/Arts.svg";
import { ReactComponent as Hiking } from "./images/interests/Hiking.svg";
import { ReactComponent as Running } from "./images/interests/Running.svg";
import { ReactComponent as Cycling } from "./images/interests/Cycling.svg";
import { ReactComponent as Walking } from "./images/interests/Walking.svg";
import { ReactComponent as Yoga } from "./images/interests/Yoga.svg";
import { ReactComponent as Reading } from "./images/interests/Reading.svg";
import { ReactComponent as Gardening } from "./images/interests/Gardening.svg";
import { ReactComponent as Painting } from "./images/interests/Painting.svg";
import { ReactComponent as Cooking } from "./images/interests/Cooking.svg";
import { ReactComponent as Question } from "./images/interests/Question.svg";

export const AzureFunctionUri = "https://comm.azurewebsites.net/";

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export async function getUserScope(getAccessTokenSilently: Function) {
  var token = await getAccessTokenSilently();
  var decodedToken: any = jwt_decode(token);
  return decodedToken.scope;
}

export function checkUserRole(currentUserRole: UserRoleModel, acceptedUserRoles: UserRoles[]) {
  if (currentUserRole && currentUserRole.role) {
    var userRole = enumFromStringValue(UserRoles, currentUserRole.role);
    if (userRole) return acceptedUserRoles.includes(userRole);
  }
  return false;
}

export async function skipWaitingAndReload() {
  var registeredSW = await navigator.serviceWorker.getRegistration();
  if (registeredSW) {
    if (registeredSW.waiting) {
      registeredSW.waiting.postMessage({ type: "SKIP_WAITING" });
    } else {
      window.location.reload();
    }
  }
}

export function UpdateReloadNotificationStorageValue(value: boolean) {
  localStorage.setItem(ReloadNotificationId, value ? "true" : "false");
  var showReloadNotificationEvent = new Event(ReloadNotificationId);
  window.dispatchEvent(showReloadNotificationEvent);
}

export function sortUserInterestsByName(userInterests: UserInterestsModel[]) {
  return userInterests.sort((a: UserInterestsModel, b: UserInterestsModel) => {
    if (a.first_name.toLowerCase() < b.first_name.toLowerCase()) {
      return -1;
    }
    if (a.first_name.toLowerCase() > b.first_name.toLowerCase()) {
      return 1;
    }
    if (a.last_name.toLowerCase() < b.last_name.toLowerCase()) {
      return -1;
    }
    if (a.last_name.toLowerCase() > b.last_name.toLowerCase()) {
      return 1;
    }
    return 0;
  });
}

export function sortUserInterestsByMatches(userInterests: UserInterestsModel[]) {
  return userInterests.sort((a: UserInterestsModel, b: UserInterestsModel) => {
    if (a.mutual_interests < b.mutual_interests) {
      return -1;
    }

    if (a.mutual_interests > b.mutual_interests) {
      return 1;
    }

    return 0;
  });
}

export function isValidHttpUrl(stringUrl: string) {
  let url;

  try {
    url = new URL(stringUrl);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
  return (Object.values(enm) as unknown as string[]).includes(value) ? (value as unknown as T) : undefined;
}

export function countInterests(interestsStr: string) {
  var count = 0;
  if (!interestsStr) {
    return count;
  }

  var interestsSplit = interestsStr.split(",");

  interestsSplit.forEach((interest) => {
    if (interest) {
      count++;
    }
  });

  return count;
}

export function getInterestIcon(interest: InterestsEnum) {
  switch (interest) {
    case InterestsEnum.Travel:
      return <Travel height={20} width={20} />;
    case InterestsEnum.Sports:
      return <Sports height={20} width={20} />;
    case InterestsEnum.Films:
      return <Films height={20} width={20} />;
    case InterestsEnum.BoardGames:
      return <BoardGames height={20} width={20} />;
    case InterestsEnum.Arts:
      return <Arts height={20} width={20} />;
    case InterestsEnum.Hiking:
      return <Hiking height={20} width={20} />;
    case InterestsEnum.Running:
      return <Running height={20} width={20} />;
    case InterestsEnum.Cycling:
      return <Cycling height={20} width={20} />;
    case InterestsEnum.Walking:
      return <Walking height={20} width={20} />;
    case InterestsEnum.Yoga:
      return <Yoga height={20} width={20} />;
    case InterestsEnum.Reading:
      return <Reading height={20} width={20} />;
    case InterestsEnum.Gardening:
      return <Gardening height={20} width={20} />;
    case InterestsEnum.Painting:
      return <Painting height={20} width={20} />;
    case InterestsEnum.Cooking:
      return <Cooking height={20} width={20} />;
    default:
      return <Question height={20} width={20} />;
  }
}

export function getEducationLevelFriendlyName(educationLevel: string) {
  switch (educationLevel) {
    case EducationEnum.HighSchool:
      return "High School / Secondary";
    case EducationEnum.College:
      return "College";
    case EducationEnum.BachelorsDegree:
      return "Bachelors Degree";
    case EducationEnum.MastersDegree:
      return "Masters Degree";
    case EducationEnum.Phd:
      return "PhD";
    default:
      return educationLevel;
  }
}

export function getRelationshipStatusFriendlyName(relationshipStatus: string) {
  switch (relationshipStatus) {
    case RelationshipStatusEnum.NeverMarried:
      return "Never Married";
    case RelationshipStatusEnum.Separated:
      return "Separated";
    case RelationshipStatusEnum.Widowed:
      return "Widowed";
    case RelationshipStatusEnum.Divorced:
      return "Divorced";
    default:
      return relationshipStatus;
  }
}
