import { useCallback, useEffect, useRef, useState } from "react";
import UserRoleModel from "./models/UserRoleModel";
import UserProfileModel, { EducationEnum, GenderEnum, RelationshipStatusEnum } from "./models/UserProfileModel";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AzureFunctionUri,
  getEducationLevelFriendlyName,
  getRelationshipStatusFriendlyName,
  useQuery,
} from "./Constants";
import { useHistory } from "react-router-dom";
import { AlertType } from "./AlertMessage";

interface Props {
  userRole: UserRoleModel;
  setAlertMessage: Function;
}
function UserInterestsEdit({ userRole, setAlertMessage }: Props) {
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState<UserProfileModel>({
    friendly_user_id: "",
    event_id: "",
    user_id: "",
    email: "",
    gender: GenderEnum.Male,
    first_name: "",
    last_name: "",
    age: "",
    phone_number: "",
    city: "",
    citizenship: "",
    facebook_url: "",
    linkedIn_url: "",
    instagram_url: "",
    profile_picture: "",
    interests: "",
    profession: "",
    education: "",
    relationship_status: RelationshipStatusEnum.NeverMarried,
    relocation: false,
    hijab: true,
    height_value: "",
    height_unit: "ft",
  } as UserProfileModel);
  const [displayImage, setDisplayImage] = useState("");

  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const isMountedRef = useRef(true);
  const isMountedRefProfileImage = useRef(true);

  const query = useQuery();
  const history = useHistory();

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var newUserProfile: any = { ...userProfile };
    if (newUserProfile) {
      newUserProfile[name] = value;
      setUserProfile(newUserProfile);
    }
  }

  function handlePhoneNumberInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    const value = target.value;

    const phoneNumberRegex = /^[0-9() +-]+$/;
    const isValueValid = phoneNumberRegex.test(value);

    if (isValueValid || !value) {
      var newUserProfile: UserProfileModel = { ...userProfile };
      if (newUserProfile) {
        newUserProfile.phone_number = value;
        setUserProfile(newUserProfile);
      }
    }
  }

  function handleHeightValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    const value = target.value;

    const heightRegex = /^[0-9'".]+$/;
    const isValueValid = heightRegex.test(value);

    if (isValueValid || !value) {
      var newUserProfile: UserProfileModel = { ...userProfile };
      if (newUserProfile) {
        newUserProfile.height_value = value;
        setUserProfile(newUserProfile);
      }
    }
  }

  const fetchProfileImage = useCallback(
    async (eventCode: string, userId: string) => {
      if (isLoading || (!isLoading && !isAuthenticated)) return;
      isMountedRefProfileImage.current = true;

      const accessToken = await getAccessTokenSilently();

      fetch(`${AzureFunctionUri}api/UserProfileImage/${eventCode}/${encodeURIComponent(userId ?? "")}`, {
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok. UserInterestsEdit (GET: UserProfileImage): ${response.statusText}(${response.status})`
            );
          }
          if (response.status === 204) {
            return undefined;
          }
          return response.blob();
        })
        .then((blob) => {
          if (isMountedRefProfileImage.current) {
            if (blob) {
              const imageUrl = URL.createObjectURL(blob);
              setDisplayImage(imageUrl);
            }
            isMountedRefProfileImage.current = false;
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          isMountedRefProfileImage.current = false;
        });
    },
    [getAccessTokenSilently, isAuthenticated, isLoading]
  );

  const fetchUserProfile = useCallback(
    async (eventCode: string, userId: string) => {
      if (isLoading || (!isLoading && !isAuthenticated)) return;
      isMountedRef.current = true;
      setLoading(true);

      const accessToken = await getAccessTokenSilently();

      fetch(`${AzureFunctionUri}api/UserProfile/${eventCode}/${encodeURIComponent(userId)}`, {
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok. UserInterestsEdit (GET: UserProfile): ${response.statusText}(${response.status})`
            );
          }
          return response.json();
        })
        .then((data: UserProfileModel) => {
          if (isMountedRef.current && data) {
            if (!data.height_unit) {
              data.height_unit = "ft";
            }

            setUserProfile(data);
            if (data.profile_picture) {
              fetchProfileImage(eventCode, userId);
            }
          }
          setLoading(false);
          //setFetchedProfile(true);
        })
        .catch((error) => {
          console.error("Error: ", error);
          setLoading(false);
          //setFetchedProfile(true);
        });
    },
    [fetchProfileImage, getAccessTokenSilently, isAuthenticated, isLoading]
  );

  async function submitForm(event: any) {
    event.preventDefault();

    if (isLoading || (!isLoading && !isAuthenticated)) return;

    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    var data = new FormData();
    data.append("user_profile", JSON.stringify(userProfile));

    fetch(
      AzureFunctionUri + "api/UserProfile/" + userRole.event_code + "/" + encodeURIComponent(userProfile.user_id ?? ""),
      {
        method: "PUT",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserInterestsEdit (PUT: UserProfile): ${response.statusText}(${response.status})`
          );
        }
        if (isMountedRef) {
          setAlertMessage({
            message: "Successfully updated profile.",
            messageType: AlertType.SuccessMessage,
          });
          setLoading(false);
          history.push("/UserInterests");
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setAlertMessage({
          message: "An error has occurred. The event admin might have disabled profile editing.",
          messageType: AlertType.ErrorMessage,
        });
        setLoading(false);
        history.push("/UserInterests");
      });
  }

  useEffect(() => {
    if (query.has("id") && query.has("eventCode")) {
      var userId = query.get("id");
      var eventCode = query.get("eventCode");
      if (userId && eventCode) {
        fetchUserProfile(eventCode, userId);
      }
    } else {
      setAlertMessage({
        message: "Failed to get user profile. Please contact admin.",
        messageType: AlertType.ErrorMessage,
      });
      setLoading(false);
      history.push("/UserInterests");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userRole]);

  return (
    <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </symbol>
      </svg>
      <h1>Edit User Profile</h1>
      {loading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loading && (
        <form onSubmit={submitForm}>
          {displayImage && (
            <>
              <div className="d-flex justify-content-center">
                <span className="border border-3 rounded-3 p-1 mb-3">
                  <img src={displayImage} className="img-fluid" alt="User Profile Pic" style={{ maxHeight: "200px" }} />
                </span>
              </div>
            </>
          )}
          <div className="mb-3">
            <label htmlFor="friendlyUserIdFormControlInput" className="form-label">
              User Id
            </label>
            <input
              type="text"
              className="form-control"
              id="friendlyUserIdFormControlInput"
              name="friendly_user_id"
              placeholder=""
              value={userProfile.friendly_user_id ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="firstNameFormControlInput" className="form-label">
              First Name <span className="text-danger fw-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="firstNameFormControlInput"
              name="first_name"
              placeholder=""
              value={userProfile.first_name ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="lastNameFormControlInput" className="form-label">
              Last Name <span className="text-danger fw-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="lastNameFormControlInput"
              name="last_name"
              placeholder=""
              value={userProfile.last_name ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="ageFormControlInput" className="form-label">
              Age <span className="text-danger fw-bold">*</span>
            </label>
            <input
              type="number"
              className="form-control"
              id="ageFormControlInput"
              min="0"
              name="age"
              placeholder=""
              value={userProfile.age ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div>
            <label className="form-label">
              Gender <span className="text-danger fw-bold">*</span>
            </label>
            <div className="d-flex">
              <div className="form-check d-inline ps-0">
                <input
                  className="form-check-input mx-2 p-0"
                  type="radio"
                  name="gender"
                  id="genderRadioM"
                  value={GenderEnum.Male}
                  checked={userProfile.gender === GenderEnum.Male}
                  onChange={handleInputChange}
                  disabled={loading}
                />
                <label className="form-check-label" htmlFor="genderRadioM">
                  Male
                </label>
              </div>
              <div className="form-check d-inline ps-0">
                <input
                  className="form-check-input mx-2 p-0"
                  type="radio"
                  name="gender"
                  id="genderRadioF"
                  value={GenderEnum.Female}
                  checked={userProfile.gender === GenderEnum.Female}
                  onChange={handleInputChange}
                  disabled={loading}
                />
                <label className="form-check-label" htmlFor="genderRadioF">
                  Female
                </label>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="phoneNumberFormControlInput" className="form-label">
              Phone Number <span className="text-danger fw-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="phoneNumberFormControlInput"
              name="phone_number"
              placeholder=""
              value={userProfile.phone_number ?? ""}
              onChange={handlePhoneNumberInputChange}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="cityFormControlInput" className="form-label">
              City of Residence <span className="text-danger fw-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="cityFormControlInput"
              name="city"
              placeholder=""
              value={userProfile.city ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="citizenshipFormControlInput" className="form-label">
              Citizenship <span className="text-danger fw-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="citizenshipFormControlInput"
              name="citizenship"
              placeholder=""
              value={userProfile.citizenship ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="heightValueFormControlInput" className="form-label">
              Height
            </label>
            <div className="row">
              <div className="col-7 col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="heightValueFormControlInput"
                  name="height_value"
                  placeholder=""
                  value={userProfile.height_value ?? ""}
                  onChange={handleHeightValueChange}
                  disabled={loading}
                />
              </div>
              <div className="col-5 col-sm-4">
                <select
                  className="form-select"
                  id="heightUnitFormControlSelect"
                  aria-label="Select Height Unit"
                  name="height_unit"
                  value={userProfile.height_unit ?? "ft"}
                  onChange={handleInputChange}
                  disabled={loading}
                >
                  <option value={"ft"} className="text-center">
                    ft
                  </option>
                  <option value={"m"} className="text-center">
                    m
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="">
              <label className="form-check-label" htmlFor="relocationCheckbox">
                <div>Are you willing to relocate?</div>
              </label>
              <div className="d-inline p-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={userProfile.relocation}
                  id="relocationCheckbox"
                  name="relocation"
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <div className="">
              <label className="form-check-label" htmlFor="hijabCheckbox">
                <div>
                  {userProfile.gender === GenderEnum.Male
                    ? "Do you expect spouse to wear hijab?"
                    : "Do you wear hijab?"}
                </div>
              </label>
              <div className="d-inline p-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={userProfile.hijab}
                  id="hijabCheckbox"
                  name="hijab"
                  onChange={handleInputChange}
                  disabled={loading}
                />
              </div>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="educationFormControlSelect" className="form-label">
              Education
            </label>
            <select
              className="form-select"
              id="educationFormControlSelect"
              aria-label="Select Education Level"
              name="education"
              value={userProfile.education ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            >
              <option value={""}>Select...</option>
              <option value={EducationEnum.HighSchool}>
                {getEducationLevelFriendlyName(EducationEnum.HighSchool)}
              </option>
              <option value={EducationEnum.College}>{getEducationLevelFriendlyName(EducationEnum.College)}</option>
              <option value={EducationEnum.BachelorsDegree}>
                {getEducationLevelFriendlyName(EducationEnum.BachelorsDegree)}
              </option>
              <option value={EducationEnum.MastersDegree}>
                {getEducationLevelFriendlyName(EducationEnum.MastersDegree)}
              </option>
              <option value={EducationEnum.Phd}>{getEducationLevelFriendlyName(EducationEnum.Phd)}</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="professionFormControlInput" className="form-label">
              Profession<span className="text-danger fw-bold">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="professionFormControlInput"
              name="profession"
              placeholder=""
              value={userProfile.profession ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="relationshipStatusFormControlSelect" className="form-label">
              Relationship Status <span className="text-danger fw-bold">*</span>
            </label>
            <div>
              <select
                className="form-select"
                id="relationshipStatusFormControlSelect"
                aria-label="Select Relationship Status"
                name="relationship_status"
                value={userProfile.relationship_status ?? RelationshipStatusEnum.NeverMarried}
                onChange={handleInputChange}
                disabled={loading}
              >
                <option value={RelationshipStatusEnum.NeverMarried}>
                  {getRelationshipStatusFriendlyName(RelationshipStatusEnum.NeverMarried)}
                </option>
                <option value={RelationshipStatusEnum.Separated}>
                  {getRelationshipStatusFriendlyName(RelationshipStatusEnum.Separated)}
                </option>
                <option value={RelationshipStatusEnum.Divorced}>
                  {getRelationshipStatusFriendlyName(RelationshipStatusEnum.Divorced)}
                </option>
                <option value={RelationshipStatusEnum.Widowed}>
                  {getRelationshipStatusFriendlyName(RelationshipStatusEnum.Widowed)}
                </option>
              </select>
            </div>
          </div>
          <div className="mb-3">
            <label htmlFor="facebookFormControlInput" className="form-label">
              Facebook Url
            </label>
            <input
              type="text"
              className="form-control"
              id="facebookFormControlInput"
              name="facebook_url"
              placeholder=""
              value={userProfile.facebook_url ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="linkedInFormControlInput" className="form-label">
              LinkedIn Url
            </label>
            <input
              type="text"
              className="form-control"
              id="linkedInFormControlInput"
              name="linkedIn_url"
              placeholder=""
              value={userProfile.linkedIn_url ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="instagramFormControlInput" className="form-label">
              Instagram Url
            </label>
            <input
              type="text"
              className="form-control"
              id="instagramFormControlInput"
              name="instagram_url"
              placeholder=""
              value={userProfile.instagram_url ?? ""}
              onChange={handleInputChange}
              disabled={loading}
            />
          </div>
          <div className="d-flex justify-content-end mb-3">
            <button
              type="submit"
              className="btn btn-success"
              disabled={
                loading ||
                !userProfile.first_name ||
                !userProfile.last_name ||
                !userProfile.age ||
                !userProfile.gender ||
                !userProfile.relationship_status
              }
            >
              {loading && (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {!loading && <>Save</>}
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default UserInterestsEdit;
