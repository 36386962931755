interface UserProfileModel {
  event_id: string;
  user_id: string;
  friendly_user_id: string;
  email: string;
  gender: string;
  first_name: string;
  last_name: string;
  age: number | "";
  phone_number: string;
  city: string;
  citizenship: string;
  relocation: boolean;
  hijab: boolean;
  education: string;
  profession: string;
  facebook_url: string;
  linkedIn_url: string;
  instagram_url: string;
  profile_picture: string;
  interests: string;
  relationship_status: string;
  height_value: string;
  height_unit: string;
}

export default UserProfileModel;

export enum GenderEnum {
  Male = "Male",
  Female = "Female",
}

export enum EducationEnum {
  HighSchool = "HighSchool",
  College = "College",
  BachelorsDegree = "BachelorsDegree",
  MastersDegree = "MastersDegree",
  Phd = "Phd",
}

export enum RelationshipStatusEnum {
  NeverMarried = "NeverMarried",
  Separated = "Separated",
  Divorced = "Divorced",
  Widowed = "Widowed",
}

export enum InterestsEnum {
  Travel = "Travel",
  Sports = "Sports",
  Films = "Films",
  BoardGames = "BoardGames",
  Arts = "Arts",
  Hiking = "Hiking",
  Running = "Running",
  Cycling = "Cycling",
  Walking = "Walking",
  Yoga = "Yoga",
  Reading = "Reading",
  Gardening = "Gardening",
  Painting = "Painting",
  Cooking = "Cooking",
}
