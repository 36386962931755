import { useAuth0 } from "@auth0/auth0-react";
import LoginButton, { LogoutButton, SignupButton } from "./AuthButtons";

function Home() {
    const { isLoading, isAuthenticated } = useAuth0();

    return (
        <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
            <h1>Home</h1>
            <div
                className="mb-2 fs-3"
                style={{
                    fontFamily: "ReithSans,Helvetica,Arial,freesans,sans-serif",
                }}
            >
                <span>
                    This app is designed to be used in conjunction with a community facilitated match making event. It is designed to allow you to make an
                    informed decision about who you would like to engage in a conversation with and start discovering if they are the one! <br />
                    The features of the app are: <br />
                    <ul>
                        <li>You do not need to download an app on you phone, it can be used from any browser on any device</li>
                        <li>Before expressing your interest, you can view their profile</li>
                        <li>Once the event is over and both parties mutually agree, their contact details will be revealed to each other</li>
                    </ul>
                    Remember to treat the information of others with utmost respect as it is meant for your eyes only. <br />
                </span>
            </div>

            <div className="row">
                {!isLoading &&
                    (isAuthenticated ? (
                        <div className="d-grid col-12 col-sm-6 offset-sm-3">
                            <LogoutButton className="btn btn-danger m-1" />
                        </div>
                    ) : (
                        <>
                            <div className="d-grid col-12 col-sm-6">
                                <LoginButton className="btn btn-primary m-1" />
                            </div>
                            <div className="d-grid col-12 col-sm-6">
                                <SignupButton className="btn btn-primary m-1" />
                            </div>
                        </>
                    ))}
            </div>
        </div>
    );
}

export default Home;
