import { useCallback, useEffect, useRef, useState } from "react";
import { Card, CloseButton, Fade } from "react-bootstrap";
import { skipWaitingAndReload, UpdateReloadNotificationStorageValue } from "./Constants";

function ReloadNotification() {
    const storageEventListenerAdded = useRef(false);
    const [show, setShow] = useState(false);

    async function handleReloadNotification() {
        UpdateReloadNotificationStorageValue(false);
        await skipWaitingAndReload();
    }

    const showReloadNotification = useCallback(async () => {
        if (localStorage.getItem(ReloadNotificationId)) {
            var value = localStorage.getItem(ReloadNotificationId) === "true";
            if (value) {
                var registeredSW = await navigator.serviceWorker.getRegistration();
                if (registeredSW?.waiting?.state === "installed") {
                    setShow(value);
                } else {
                    UpdateReloadNotificationStorageValue(false);
                    setShow(false);
                }
            }
        }
    }, []);

    useEffect(() => {
        showReloadNotification();

        if (!storageEventListenerAdded.current) {
            window.addEventListener(ReloadNotificationId, showReloadNotification);
            storageEventListenerAdded.current = true;
        }
    }, [showReloadNotification]);

    return (
        <>
            <div>
                <Fade in={show} unmountOnExit>
                    <Card className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                        <Card.Header className="bg-success d-flex justify-content-between">
                            <strong className="text-white">Update Available</strong>
                            <CloseButton variant="white" onClick={() => setShow(false)} />
                        </Card.Header>
                        <Card.Body>
                            <Card.Text className="m-0">
                                A new version of this app is available. <br />
                            </Card.Text>
                            <button className="btn btn-success m-0 align-baseline" style={{ fontSize: "inherit" }} onClick={handleReloadNotification}>
                                Click here
                            </button>{" "}
                            to refresh the app.
                        </Card.Body>
                    </Card>
                </Fade>
            </div>
        </>
    );
}

export default ReloadNotification;
export const ReloadNotificationId = "showReloadNotification";
