import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Accordion, Button, Modal } from "react-bootstrap";
import {
  AzureFunctionUri,
  countInterests,
  getEducationLevelFriendlyName,
  getInterestIcon,
  getRelationshipStatusFriendlyName,
} from "./Constants";
import UserProfileModel, {
  EducationEnum,
  GenderEnum,
  InterestsEnum,
  RelationshipStatusEnum,
} from "./models/UserProfileModel";
import { ReactComponent as UserProfileIcon } from "./images/person-circle.svg";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { ReactComponent as EditPencil } from "./images/pencil-fill.svg";
import { Link } from "react-router-dom";
import EventModel from "./models/EventModel";
import { AlertType } from "./AlertMessage";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";
import StripeCreateSessionModel from "./models/StripeCreateSessionModel";

let stripePromise: Promise<Stripe | null>;

const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? "");
  }
  return stripePromise;
};

interface Props {
  userRole: UserRoleModel;
  loadingUserRole: boolean;
  getUserRole: Function;
  setAlertMessage: Function;
}
function UserProfile({ userRole, loadingUserRole, getUserRole, setAlertMessage }: Props) {
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [fetchedProfile, setFetchedProfile] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [showTandCModal, setShowTandCModal] = useState(false);
  const [displayImage, setDisplayImage] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [eventInfo, setEventInfo] = useState({} as EventModel);
  const [userRoleLocal, setUserRoleLocal] = useState({ ...userRole });
  const [showInterestsModal, setShowInterestsModal] = useState(false);

  const [userProfile, setUserProfile] = useState({
    friendly_user_id: "",
    event_id: "",
    user_id: "",
    email: "",
    gender: GenderEnum.Male,
    first_name: "",
    last_name: "",
    age: "",
    phone_number: "",
    city: "",
    citizenship: "",
    facebook_url: "",
    linkedIn_url: "",
    instagram_url: "",
    profile_picture: "",
    interests: "",
    profession: "",
    education: "",
    relationship_status: RelationshipStatusEnum.NeverMarried,
    relocation: false,
    hijab: true,
    height_value: "",
    height_unit: "ft",
  } as UserProfileModel);
  const [selectedImage, setSelectedImage] = useState({} as File);

  const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const isMountedRef = useRef(true);
  const isMountedEventInfoRef = useRef(true);
  const isMountedRefProfileImage = useRef(true);

  function handleInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var newUserProfile: any = { ...userProfile };
    if (newUserProfile) {
      newUserProfile[name] = value;
      setUserProfile(newUserProfile);
    }
  }

  function handlePhoneNumberInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    const value = target.value;

    const phoneNumberRegex = /^[0-9() +-]+$/;
    const isValueValid = phoneNumberRegex.test(value);

    if (isValueValid || !value) {
      var newUserProfile: UserProfileModel = { ...userProfile };
      if (newUserProfile) {
        newUserProfile.phone_number = value;
        setUserProfile(newUserProfile);
      }
    }
  }

  function handleHeightValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    const target = e.target;
    const value = target.value;

    const heightRegex = /^[0-9'".]+$/;
    const isValueValid = heightRegex.test(value);

    if (isValueValid || !value) {
      var newUserProfile: UserProfileModel = { ...userProfile };
      if (newUserProfile) {
        newUserProfile.height_value = value;
        setUserProfile(newUserProfile);
      }
    }
  }

  function handleEventCodeInputChange(event: any) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    var newUserRole: any = { ...userRoleLocal };
    if (newUserRole) {
      newUserRole[name] = value;
      setUserRoleLocal(newUserRole);
    }
  }

  function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
    var profile_picture = "";
    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      profile_picture = file.name;
      setSelectedImage(file);
      setDisplayImage(URL.createObjectURL(file));
    } else {
      setSelectedImage({} as File);
    }

    var updatedProfile = { ...userProfile };
    updatedProfile.profile_picture = profile_picture;
    setUserProfile(updatedProfile);
  }

  function toggleInterest(interest: InterestsEnum) {
    var updatedProfile = { ...userProfile };
    var interestsSplit = userProfile.interests?.split(",") ?? ([] as string[]);

    if (userProfile.interests?.includes(interest)) {
      interestsSplit = interestsSplit.filter((v) => v !== interest);
    } else {
      interestsSplit.push(interest);
    }
    interestsSplit = interestsSplit.filter((e) => e);
    updatedProfile.interests = interestsSplit.join(",");
    setUserProfile(updatedProfile);
  }

  const fetchProfileImage = useCallback(async () => {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedRefProfileImage.current = true;
    setLoadingImage(true);

    const accessToken = await getAccessTokenSilently();

    fetch(`${AzureFunctionUri}api/UserProfileImage/${userRole.event_code}/${encodeURIComponent(user?.sub ?? "")}`, {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserProfile (GET: UserProfileImage): ${response.statusText}(${response.status})`
          );
        }
        if (response.status === 204) {
          return undefined;
        }
        return response.blob();
      })
      .then((blob) => {
        if (isMountedRefProfileImage.current) {
          if (blob) {
            const imageUrl = URL.createObjectURL(blob);
            setDisplayImage(imageUrl);
          }
          setLoadingImage(false);
          isMountedRefProfileImage.current = false;
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoadingImage(false);
        isMountedRefProfileImage.current = false;
      });
  }, [getAccessTokenSilently, isAuthenticated, isLoading, user?.sub, userRole.event_code]);

  const fetchUserProfile = useCallback(async () => {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    fetch(`${AzureFunctionUri}api/UserProfile/${userRole.event_code}/${encodeURIComponent(user?.sub ?? "")}`, {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserProfile (GET: UserProfile): ${response.statusText}(${response.status})`
          );
        }
        return response.json();
      })
      .then((data: UserProfileModel) => {
        if (isMountedRef.current && data) {
          if (!data.height_unit) {
            data.height_unit = "ft";
          }

          setUserProfile(data);
          if (data.profile_picture) {
            fetchProfileImage();
          }
        }
        setLoading(false);
        setFetchedProfile(true);
      })
      .catch((error) => {
        console.error("Error: ", error);
        setLoading(false);
        setFetchedProfile(true);
      });
  }, [fetchProfileImage, getAccessTokenSilently, isAuthenticated, isLoading, user?.sub, userRole.event_code]);

  const fetchEventInfo = useCallback(async () => {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedEventInfoRef.current = true;

    const accessToken = await getAccessTokenSilently();

    fetch(`${AzureFunctionUri}api/Events/${userRole.event_code}`, {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserProfile (GET: Events): ${response.statusText}(${response.status})`
          );
        }
        return response.json();
      })
      .then((data: EventModel) => {
        if (isMountedEventInfoRef.current && data) {
          setEventInfo(data);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, [getAccessTokenSilently, isAuthenticated, isLoading, userRole.event_code]);

  async function registerForEvent(event: any) {
    event.preventDefault();

    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    fetch(AzureFunctionUri + "api/RegisterEvent/" + userRoleLocal?.event_code + "/" + user?.email, {
      method: "POST",
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (response.status === 404) {
          isMountedRef.current = false;
          setAlertMessage({
            message:
              "An error occurred. You have either entered an invalid event code or the event admin has not yet processed your payment.",
            messageType: AlertType.ErrorMessage,
          });
          setLoading(false);
        } else if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserProfile (POST: RegisterEvent): ${response.statusText}(${response.status})`
          );
        } else {
          isMountedRef.current = false;
          setLoading(false);
          setAlertMessage({
            message: "Successfully registered for event.",
            messageType: AlertType.SuccessMessage,
          });
          getUserRole();
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setAlertMessage({
          message: "An error has occurred.",
          messageType: AlertType.ErrorMessage,
        });
        setLoading(false);
      });
  }

  async function submitForm(event: any) {
    event.preventDefault();

    if (isLoading || (!isLoading && !isAuthenticated)) return;

    let age = typeof userProfile.age === "string" ? parseInt(userProfile.age) : userProfile.age;

    if (age < eventInfo.age_lower || age > eventInfo.age_upper) {
      window.scrollTo(0, 0);
      setAlertMessage({
        message:
          `Profile cannot be saved. You don't meet the age requirements for this event. You must be between ${eventInfo.age_lower}` +
          ` and ${eventInfo.age_upper} years to participate in this event.`,
        messageType: AlertType.WarningMessage,
      });
      return;
    }

    isMountedRef.current = true;
    setLoading(true);

    const accessToken = await getAccessTokenSilently();

    var updatedProfile = { ...userProfile };
    updatedProfile.user_id = user?.sub ?? "";
    updatedProfile.email = user?.email ?? "";
    setUserProfile(updatedProfile);

    var data = new FormData();
    data.append("user_profile", JSON.stringify(updatedProfile));
    if (selectedImage && selectedImage.name) {
      data.append("profile_picture", selectedImage);
    }

    fetch(
      AzureFunctionUri +
        "api/UserProfile/" +
        userRole.event_code +
        "/" +
        encodeURIComponent(updatedProfile.user_id ?? ""),
      {
        method: "PUT",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
        body: data,
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserProfile (PUT: UserProfile): ${response.statusText}(${response.status})`
          );
        }
        if (isMountedRef) {
          setAlertMessage({
            message: "Successfully updated profile.",
            messageType: AlertType.SuccessMessage,
          });
          window.scrollTo(0, 0);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setAlertMessage({
          message: "An error has occurred. The event admin might have disabled profile editing.",
          messageType: AlertType.ErrorMessage,
        });
        window.scrollTo(0, 0);
        setLoading(false);
      });
  }

  async function createPaymentSession() {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedRef.current = true;
    setLoadingPayment(true);

    const accessToken = await getAccessTokenSilently();

    fetch(
      AzureFunctionUri +
        "api/Payments/CreateSession/" +
        userRole?.event_code +
        "?redirectUrl=" +
        encodeURI(window.location.origin),
      {
        method: "POST",
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. UserProfile (POST: Payments/CreateSession): ${response.statusText}(${response.status})`
          );
        }
        return response.json();
      })
      .then(async (data: StripeCreateSessionModel) => {
        if (data) {
          setLoadingPayment(false);
          var stripe = await getStripe();
          console.log(data.session_id);
          stripe?.redirectToCheckout({ sessionId: data.session_id });
        }
      })
      .catch((error) => {
        console.error("Error: ", error);
        setAlertMessage({
          message: "An error has occurred while trying to make a payment.",
          messageType: AlertType.ErrorMessage,
        });
        setLoadingPayment(false);
      });
  }

  useEffect(() => {
    if (!isLoading && isAuthenticated && !fetchedProfile && userRole.event_code) {
      fetchUserProfile();
    }

    return () => {
      isMountedRef.current = false;
    };
  }, [fetchUserProfile, fetchedProfile, isLoading, isAuthenticated, userRole.event_code]);

  useEffect(() => {
    if (userRole.event_code) {
      fetchEventInfo();
    }

    return () => {
      isMountedEventInfoRef.current = false;
    };
  }, [fetchEventInfo, userRole.event_code]);

  return (
    <div className="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
        <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
          <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
        </symbol>
      </svg>
      <h1>User Profile</h1>
      {(loading || loadingUserRole) && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loadingUserRole && (
        <>
          {!userRole.event_code && (
            <form onSubmit={registerForEvent}>
              <div className="mb-3">
                <label htmlFor="eventCodeFormControlInput" className="form-label">
                  Event Code
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="eventCodeFormControlInput"
                  name="event_code"
                  placeholder=""
                  value={userRoleLocal.event_code ?? ""}
                  onChange={handleEventCodeInputChange}
                  disabled={loadingUserRole || eventInfo.profile_locked}
                />
              </div>
              <div className="d-flex justify-content-end mb-3">
                <button type="submit" className="btn btn-success" disabled={loading || loadingUserRole}>
                  {loading && (
                    <div className="spinner-border spinner-border-sm" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                  {!loading && <>Save</>}
                </button>
              </div>
            </form>
          )}
          {userRole.event_code && eventInfo && (
            <>
              <div className="mb-2">
                <Accordion defaultActiveKey="0">
                  {eventInfo.description && (
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Event Information</Accordion.Header>
                      <Accordion.Body>
                        {eventInfo.description.split("\n").map((e: string, index: number) => {
                          if (!e) {
                            return <br key={index} />;
                          }
                          return <div key={index}>{e}</div>;
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  {eventInfo.payment_required && !userRole.payment_reference && userRole.role === UserRoles.Other && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Payments</Accordion.Header>
                      <Accordion.Body>
                        <div>
                          Payment is required to register for this event. Click the button below to proceed to payment.
                          <br />
                          Note: If you have already made the payment, please wait a few minutes while we confirm your
                          payment.
                        </div>
                        <div>
                          <button
                            className="btn btn-primary"
                            onClick={() => createPaymentSession()}
                            disabled={loadingPayment}
                          >
                            Proceed to payment
                          </button>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </div>
              <form onSubmit={submitForm}>
                {displayImage && (
                  <>
                    <div className="d-flex justify-content-center">
                      <span className="border border-3 rounded-3 p-1 mb-3">
                        <img
                          src={displayImage}
                          className="img-fluid"
                          alt="User Profile Pic"
                          style={{ maxHeight: "200px" }}
                          onClick={() => setShowImageModal(true)}
                        />
                      </span>
                      <div className="mt-auto">
                        <a
                          className="btn btn-light btn-outline-secondary btn-sm d-flex align-items-center"
                          style={{
                            transform: "translate(-30px, -10px)",
                          }}
                          href="#formImageUpload"
                        >
                          <EditPencil width={16} height={16} />
                        </a>
                      </div>
                    </div>
                  </>
                )}
                {!displayImage && (
                  <div className="d-flex justify-content-center">
                    <span className="border border-3 rounded-3 p-1 mb-3">
                      <a href="#formImageUpload">
                        <svg
                          className="bd-placeholder-img card-img-top"
                          width="150"
                          height="150"
                          xmlns="http://www.w3.org/2000/svg"
                          role="img"
                          aria-label="Placeholder"
                          preserveAspectRatio="xMidYMid slice"
                          focusable="false"
                        >
                          <title>Placeholder</title>
                          <rect width="100%" height="100%" fill="#868e96"></rect>
                          <UserProfileIcon className="m-0" width="50" height="50" fill="white" x={50} y={50} />
                        </svg>
                      </a>
                    </span>
                  </div>
                )}
                <div className="mb-3">
                  <span>User Id: </span>
                  <span
                    className={
                      "badge" +
                      (userProfile.friendly_user_id
                        ? userProfile.gender === GenderEnum.Male
                          ? " text-bg-primary"
                          : " text-bg-danger"
                        : " text-bg-secondary")
                    }
                  >
                    {userProfile.friendly_user_id ?? "N/A"}
                  </span>
                </div>
                <div className="mb-3">
                  <label htmlFor="firstNameFormControlInput" className="form-label">
                    First Name <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstNameFormControlInput"
                    name="first_name"
                    placeholder=""
                    value={userProfile.first_name ?? ""}
                    onChange={handleInputChange}
                    disabled={loading || (eventInfo.profile_locked && userProfile.user_id) ? true : false}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="lastNameFormControlInput" className="form-label">
                    Last Name <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastNameFormControlInput"
                    name="last_name"
                    placeholder=""
                    value={userProfile.last_name ?? ""}
                    onChange={handleInputChange}
                    disabled={loading || (eventInfo.profile_locked && userProfile.user_id) ? true : false}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="ageFormControlInput" className="form-label">
                    Age <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="ageFormControlInput"
                    min="0"
                    name="age"
                    placeholder=""
                    value={userProfile.age ?? ""}
                    onChange={handleInputChange}
                    disabled={loading || (eventInfo.profile_locked && userProfile.user_id) ? true : false}
                  />
                </div>
                <div>
                  <label className="form-label">
                    Gender <span className="text-danger fw-bold">*</span>
                  </label>
                  <div className="d-flex">
                    <div className="form-check d-inline ps-0">
                      <input
                        className="form-check-input mx-2 p-0"
                        type="radio"
                        name="gender"
                        id="genderRadioM"
                        value={GenderEnum.Male}
                        checked={userProfile.gender === GenderEnum.Male}
                        onChange={handleInputChange}
                        disabled={loading || (eventInfo.profile_locked && userProfile.user_id) ? true : false}
                      />
                      <label className="form-check-label" htmlFor="genderRadioM">
                        Male
                      </label>
                    </div>
                    <div className="form-check d-inline ps-0">
                      <input
                        className="form-check-input mx-2 p-0"
                        type="radio"
                        name="gender"
                        id="genderRadioF"
                        value={GenderEnum.Female}
                        checked={userProfile.gender === GenderEnum.Female}
                        onChange={handleInputChange}
                        disabled={loading || (eventInfo.profile_locked && userProfile.user_id) ? true : false}
                      />
                      <label className="form-check-label" htmlFor="genderRadioF">
                        Female
                      </label>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="phoneNumberFormControlInput" className="form-label">
                    Phone Number <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phoneNumberFormControlInput"
                    name="phone_number"
                    placeholder=""
                    value={userProfile.phone_number ?? ""}
                    onChange={handlePhoneNumberInputChange}
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="cityFormControlInput" className="form-label">
                    City of Residence <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cityFormControlInput"
                    name="city"
                    placeholder=""
                    value={userProfile.city ?? ""}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="citizenshipFormControlInput" className="form-label">
                    Citizenship <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="citizenshipFormControlInput"
                    name="citizenship"
                    placeholder=""
                    value={userProfile.citizenship ?? ""}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="heightValueFormControlInput" className="form-label">
                    Height
                  </label>
                  <div className="row">
                    <div className="col-7 col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        id="heightValueFormControlInput"
                        name="height_value"
                        placeholder=""
                        value={userProfile.height_value ?? ""}
                        onChange={handleHeightValueChange}
                        disabled={loading}
                      />
                    </div>
                    <div className="col-5 col-sm-4">
                      <select
                        className="form-select"
                        id="heightUnitFormControlSelect"
                        aria-label="Select Height Unit"
                        name="height_unit"
                        value={userProfile.height_unit ?? "ft"}
                        onChange={handleInputChange}
                        disabled={loading}
                      >
                        <option value={"ft"} className="text-center">
                          ft
                        </option>
                        <option value={"m"} className="text-center">
                          m
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="">
                    <label className="form-check-label" htmlFor="relocationCheckbox">
                      <div>Are you willing to relocate?</div>
                    </label>
                    <div className="d-inline p-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile.relocation}
                        id="relocationCheckbox"
                        name="relocation"
                        onChange={handleInputChange}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="">
                    <label className="form-check-label" htmlFor="hijabCheckbox">
                      <div>
                        {userProfile.gender === GenderEnum.Male
                          ? "Do you expect spouse to wear hijab?"
                          : "Do you wear hijab?"}
                      </div>
                    </label>
                    <div className="d-inline p-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={userProfile.hijab}
                        id="hijabCheckbox"
                        name="hijab"
                        onChange={handleInputChange}
                        disabled={loading}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="educationFormControlSelect" className="form-label">
                    Education
                  </label>
                  <select
                    className="form-select"
                    id="educationFormControlSelect"
                    aria-label="Select Education Level"
                    name="education"
                    value={userProfile.education ?? ""}
                    onChange={handleInputChange}
                    disabled={loading || (eventInfo.profile_locked && userProfile.user_id) ? true : false}
                  >
                    <option value={""}>Select...</option>
                    <option value={EducationEnum.HighSchool}>
                      {getEducationLevelFriendlyName(EducationEnum.HighSchool)}
                    </option>
                    <option value={EducationEnum.College}>
                      {getEducationLevelFriendlyName(EducationEnum.College)}
                    </option>
                    <option value={EducationEnum.BachelorsDegree}>
                      {getEducationLevelFriendlyName(EducationEnum.BachelorsDegree)}
                    </option>
                    <option value={EducationEnum.MastersDegree}>
                      {getEducationLevelFriendlyName(EducationEnum.MastersDegree)}
                    </option>
                    <option value={EducationEnum.Phd}>{getEducationLevelFriendlyName(EducationEnum.Phd)}</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="professionFormControlInput" className="form-label">
                    Profession<span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="professionFormControlInput"
                    name="profession"
                    placeholder=""
                    value={userProfile.profession ?? ""}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="relationshipStatusFormControlSelect" className="form-label">
                    Relationship Status <span className="text-danger fw-bold">*</span>
                  </label>
                  <div>
                    <select
                      className="form-select"
                      id="relationshipStatusFormControlSelect"
                      aria-label="Select Relationship Status"
                      name="relationship_status"
                      value={userProfile.relationship_status ?? RelationshipStatusEnum.NeverMarried}
                      onChange={handleInputChange}
                      disabled={loading || (eventInfo.profile_locked && userProfile.user_id) ? true : false}
                    >
                      <option value={RelationshipStatusEnum.NeverMarried}>
                        {getRelationshipStatusFriendlyName(RelationshipStatusEnum.NeverMarried)}
                      </option>
                      <option value={RelationshipStatusEnum.Separated}>
                        {getRelationshipStatusFriendlyName(RelationshipStatusEnum.Separated)}
                      </option>
                      <option value={RelationshipStatusEnum.Divorced}>
                        {getRelationshipStatusFriendlyName(RelationshipStatusEnum.Divorced)}
                      </option>
                      <option value={RelationshipStatusEnum.Widowed}>
                        {getRelationshipStatusFriendlyName(RelationshipStatusEnum.Widowed)}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="facebookFormControlInput" className="form-label">
                    Facebook Url
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="facebookFormControlInput"
                    name="facebook_url"
                    placeholder=""
                    value={userProfile.facebook_url ?? ""}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="linkedInFormControlInput" className="form-label">
                    LinkedIn Url
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="linkedInFormControlInput"
                    name="linkedIn_url"
                    placeholder=""
                    value={userProfile.linkedIn_url ?? ""}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="instagramFormControlInput" className="form-label">
                    Instagram Url
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="instagramFormControlInput"
                    name="instagram_url"
                    placeholder=""
                    value={userProfile.instagram_url ?? ""}
                    onChange={handleInputChange}
                    disabled={loading}
                  />
                </div>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                  <div>Hobbies</div>
                  <div>
                    <button className="btn btn-primary" type="button" onClick={() => setShowInterestsModal(true)}>
                      {countInterests(userProfile.interests) + " selected"}
                    </button>
                  </div>
                </div>
                <div className="mb-3">
                  <label htmlFor="formImageUpload" className="form-label">
                    Upload Photo
                  </label>
                  <input
                    className="form-control"
                    type="file"
                    accept="image/*"
                    id="formImageUpload"
                    name="profile_picture"
                    onChange={handleFileChange}
                    disabled={loading || loadingImage}
                  />
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={agreement}
                      id="agreementCheckbox1"
                      onChange={(e) => {
                        setAgreement(e.target.checked);
                      }}
                    />
                    <label className="form-check-label" htmlFor="agreementCheckbox1">
                      <div>
                        <span className="text-danger fw-bold">*</span> I accept these{" "}
                        <Link to="#" onClick={() => setShowTandCModal(true)}>
                          Terms and Conditions.
                        </Link>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={
                      loading ||
                      !agreement ||
                      !userProfile.first_name ||
                      !userProfile.last_name ||
                      !userProfile.age ||
                      !userProfile.phone_number ||
                      !userProfile.gender ||
                      !userProfile.city ||
                      !userProfile.citizenship ||
                      !userProfile.profession ||
                      !userProfile.relationship_status
                    }
                  >
                    {loading && (
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                    {!loading && <>Save</>}
                  </button>
                </div>
              </form>
            </>
          )}
          <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Profile Picture</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex align-items-center p-1">
              {displayImage && (
                <div className="d-flex justify-content-center">
                  <img src={displayImage} className="img-fluid" alt="User Profile Pic" />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowImageModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showTandCModal} onHide={() => setShowTandCModal(false)} fullscreen="md-down">
            <Modal.Header closeButton>
              <Modal.Title>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              All users are required to adhere to high standards of conduct at all times, and therefore we ask that
              users behave appropriately, respectfully and courteously in all interactions with other users. <br />
              You agree not to: <br />
              <ul>
                <li>
                  Harass or cause a nuisance, inconvenience, distress or anxiety or violate the privacy of anyone
                  introduce you to.
                </li>
                <li>Do anything which restricts anyone else’s use of the service provided.</li>
                <li>
                  Do anything that deliberately or recklessly prejudices or damages the reputation of the community and
                  its volunteers
                </li>
                <li>
                  Discuss or show any details of other users, that we have supplied to you, to any other users or
                  non-users at any time
                </li>
              </ul>
              <hr />
              In consideration of participating in one or more matchmaking’s programs, I hereby confirm that I am at
              least 18, single; either never married, divorced, widowed or separated (divorce pending and not currently
              living with my spouse) and I will ensure that I uphold Islamic values of modesty and honesty during the
              event and treat all other participants with respect.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowTandCModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal show={showInterestsModal} onHide={() => setShowInterestsModal(false)} fullscreen="md-down">
            <Modal.Header closeButton>
              <Modal.Title>Hobbies</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                {Object.keys(InterestsEnum).map((key, index) => {
                  return (
                    <div className="col-11 col-sm-3 col-md-4 col-xl-4 d-flex m-2" key={index}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={key}
                          id={"flexCheck" + index}
                          checked={userProfile.interests?.includes(key)}
                          onChange={() => {
                            toggleInterest(key as InterestsEnum);
                          }}
                        />
                        <label className="form-check-label" htmlFor={"flexCheck" + index}>
                          <div>
                            <div className="d-inline">{getInterestIcon(key as InterestsEnum)}</div>
                            <div className="d-inline ms-1 align-middle">{key}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowInterestsModal(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </div>
  );
}

export default UserProfile;
