import "@stripe/stripe-js";
import React, { useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import { AzureFunctionUri } from "./Constants";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import AlertMessage, { AlertMessageModel, AlertType } from "./AlertMessage";
import ContentContainer from "./ContentContainer";
import ReloadNotification from "./ReloadNotification";
import Footer from "./Footer";
import Home from "./Home";
import Settings from "./Settings";
import Sidebar from "./Sidebar";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "./ProtectedRoute";
import Profile from "./Profile";
import UserProfile from "./UserProfile";
import Users from "./Users";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";
import UserProfiles from "./UserProfiles";
import UserInterests from "./UserInterests";
import Events from "./Events";
import CreateEvent from "./CreateEvent";
import EditEvent from "./EditEvent";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
import LoggedInNotification from "./LoggedInNotification";
import UserInterestsEdit from "./UserInterestsEdit";
import EventUsers from "./EventUsers";

function App() {
  const [showSettings, setShowSettings] = useState(false);
  const [apiVersion, setApiVersion] = useState("");
  const [isLoadingUserRole, setLoadingUserRole] = useState(true);
  const [userRole, setUserRole] = useState({ role: UserRoles.Other } as UserRoleModel);
  const [alertMessage, setAlertMessage] = useState({
    message: undefined,
    messageType: AlertType.InfoMessage,
  } as AlertMessageModel);

  const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const isMountedRefUserRole = useRef(true);

  function getApiVersion() {
    fetch(AzureFunctionUri + "api/Version", {
      cache: "no-cache",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Network response was not ok. App (GET: Verison): " + response.statusText + "(" + response.status + ")"
          );
        }
        return response.text();
      })
      .then((data) => {
        setApiVersion(data);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }

  useEffect(() => {
    getApiVersion();
  }, []);

  const getUserRole = useCallback(async () => {
    if (isLoading || (!isLoading && !isAuthenticated)) return;
    isMountedRefUserRole.current = true;

    const accessToken = await getAccessTokenSilently();
    fetch(AzureFunctionUri + "api/UserRoles/" + encodeURIComponent(user?.sub ?? ""), {
      cache: "no-cache",
      headers: {
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Network response was not ok. App (GET: UserRoles): ${response.statusText}(${response.status})`
          );
        }

        return response.json();
      })
      .then((data: UserRoleModel) => {
        if (isMountedRefUserRole.current) {
          setUserRole(data ?? ({ role: UserRoles.Other } as UserRoleModel));
          setLoadingUserRole(false);
        }
      })
      .catch((error) => {
        console.log("Error: " + error);
        setUserRole({ role: UserRoles.Other } as UserRoleModel);
        setLoadingUserRole(false);
      });
  }, [getAccessTokenSilently, isAuthenticated, isLoading, user?.sub]);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getUserRole();
    }
  }, [getUserRole, isAuthenticated, isLoading]);

  return (
    <Router>
      <Sidebar showSettings={setShowSettings} userRole={userRole} isLoadingUserRole={isLoadingUserRole} />

      <ContentContainer>
        <ReloadNotification />
        <LoggedInNotification user={user} />
        <AlertMessage
          {...alertMessage}
          clearMessage={() => {
            setAlertMessage({
              ...alertMessage,
              message: undefined,
            });
          }}
        />
        <Switch>
          <Route path="/Home">
            <Home />
          </Route>
          <Route path="/Profile">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              setAlertMessage={setAlertMessage}
            >
              <Profile setAlertMessage={setAlertMessage} />
            </ProtectedRoute>
          </Route>
          <Route path="/UserProfiles">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.User]}
              setAlertMessage={setAlertMessage}
            >
              <UserProfiles userRole={userRole} loadingUserRole={isLoadingUserRole} setAlertMessage={setAlertMessage} />
            </ProtectedRoute>
          </Route>
          <Route path="/UserProfile">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              setAlertMessage={setAlertMessage}
            >
              <UserProfile
                userRole={userRole}
                loadingUserRole={isLoadingUserRole}
                getUserRole={getUserRole}
                setAlertMessage={setAlertMessage}
              />
            </ProtectedRoute>
          </Route>
          <Route path="/Users">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.EventOwner, UserRoles.Admin]}
              setAlertMessage={setAlertMessage}
            >
              <Users userRole={userRole} />
            </ProtectedRoute>
          </Route>
          <Route path="/UserInterests/Edit">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.EventOwner, UserRoles.Admin]}
              setAlertMessage={setAlertMessage}
            >
              <UserInterestsEdit userRole={userRole} setAlertMessage={setAlertMessage} />
            </ProtectedRoute>
          </Route>
          <Route path="/UserInterests">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.Facilitator, UserRoles.EventOwner, UserRoles.Admin]}
              setAlertMessage={setAlertMessage}
            >
              <UserInterests
                userRole={userRole}
                loadingUserRole={isLoadingUserRole}
                getUserRole={getUserRole}
                setAlertMessage={setAlertMessage}
              />
            </ProtectedRoute>
          </Route>
          <Route path="/Events/Edit">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.Admin, UserRoles.EventOwner]}
              setAlertMessage={setAlertMessage}
            >
              <EditEvent userRole={userRole} setAlertMessage={setAlertMessage} />
            </ProtectedRoute>
          </Route>
          <Route path="/Events/Create">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.Admin, UserRoles.EventOwner]}
              setAlertMessage={setAlertMessage}
            >
              <CreateEvent userRole={userRole} setAlertMessage={setAlertMessage} />
            </ProtectedRoute>
          </Route>
          <Route path="/Events">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.Admin, UserRoles.EventOwner]}
              setAlertMessage={setAlertMessage}
            >
              <Events userRole={userRole} setAlertMessage={setAlertMessage} />
            </ProtectedRoute>
          </Route>
          <Route path="/EventUsers">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.Admin, UserRoles.EventOwner]}
              setAlertMessage={setAlertMessage}
            >
              <EventUsers userRole={userRole} setAlertMessage={setAlertMessage} />
            </ProtectedRoute>
          </Route>
          <Route path="/PaymentSuccess">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.Other, UserRoles.User]}
              setAlertMessage={setAlertMessage}
            >
              <PaymentSuccess userRole={userRole} getUserRole={getUserRole} setAlertMessage={setAlertMessage} />
            </ProtectedRoute>
          </Route>
          <Route path="/PaymentFailed">
            <ProtectedRoute
              currentUserRole={userRole}
              isLoadingUserRole={isLoadingUserRole}
              userRoles={[UserRoles.Other, UserRoles.User]}
              setAlertMessage={setAlertMessage}
            >
              <PaymentFailed setAlertMessage={setAlertMessage} />
            </ProtectedRoute>
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </ContentContainer>

      <Settings show={showSettings} setShow={setShowSettings} apiVersion={apiVersion} />

      <Footer />
    </Router>
  );
}

export default App;
