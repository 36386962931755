import { useCallback, useEffect, useRef, useState } from "react";
import { Button, ButtonGroup, Modal, ToggleButton } from "react-bootstrap";
import UserProfileModel, { GenderEnum, InterestsEnum } from "./models/UserProfileModel";
import { ReactComponent as UserProfileIcon } from "./images/person-circle.svg";
import FacebookLogo from "./images/FacebookLogo.png";
import LinkedInLogo from "./images/LinkedInLogo.png";
import InstagramLogo from "./images/InstagramLogo.png";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AzureFunctionUri,
  countInterests,
  getEducationLevelFriendlyName,
  getInterestIcon,
  getRelationshipStatusFriendlyName,
  isValidHttpUrl,
} from "./Constants";
import { UserDecisionModel } from "./models/UserDecisionModel";
import AlertMessage, { AlertMessageModel, AlertType } from "./AlertMessage";

export enum UserDecisionEnum {
  Interested = "Interested",
  NotInterested = "NotInterested",
  Neither = "None",
}

interface Props {
  userProfile: UserProfileModel;
  incomingDecision?: UserDecisionEnum;
  outgoingDecision?: UserDecisionEnum;
  fetchOutgoingDecisions: Function;
  closeModal: Function;
}

function UserProfileModal({
  userProfile,
  incomingDecision = UserDecisionEnum.Neither,
  outgoingDecision = UserDecisionEnum.Neither,
  fetchOutgoingDecisions,
  closeModal,
}: Props) {
  const [show, setShow] = useState(true);
  const [showImageModal, setShowImageModal] = useState(false);
  const [saving, setSaving] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: undefined,
    messageType: AlertType.InfoMessage,
  } as AlertMessageModel);
  const [userImage, setUserImage] = useState("");
  const [userDecision, setUserDecision] = useState(outgoingDecision);
  const [showInterestsModal, setShowInterestsModal] = useState(false);

  const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const isMountedRefProfileImage = useRef(true);
  const isMountedUserDecisionsRef = useRef(true);

  const fetchUserImage = useCallback(
    async (eventId: string, userId: string) => {
      if (isLoading || (!isLoading && !isAuthenticated)) return;
      isMountedRefProfileImage.current = true;

      const accessToken = await getAccessTokenSilently();

      fetch(`${AzureFunctionUri}api/UserProfileImage/${eventId}/${userId}`, {
        cache: "no-cache",
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok. UserProfileModal (GET: UserProfileImage): ${response.statusText}(${response.status})`
            );
          }
          if (response.status === 204) {
            return undefined;
          }
          return response.blob();
        })
        .then((blob) => {
          if (isMountedRefProfileImage.current) {
            if (blob) {
              const imageUrl = URL.createObjectURL(blob);
              setUserImage(imageUrl);
            }
            isMountedRefProfileImage.current = false;
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          isMountedRefProfileImage.current = false;
        });
    },
    [getAccessTokenSilently, isAuthenticated, isLoading]
  );

  const submitUserDecision = useCallback(
    async (userDecisionModel: UserDecisionModel) => {
      if (isLoading || (!isLoading && !isAuthenticated)) return;

      isMountedUserDecisionsRef.current = true;
      setSaving(true);

      const accessToken = await getAccessTokenSilently();

      fetch(
        AzureFunctionUri +
          "api/UserDecisions/" +
          encodeURIComponent(user?.sub ?? "") +
          "/" +
          encodeURIComponent(userProfile.user_id),
        {
          method: "PUT",
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken,
          },
          body: JSON.stringify(userDecisionModel),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              `Network response was not ok. UserProfileModal (PUT: UserDecisions): ${response.statusText}(${response.status})`
            );
          }
          if (isMountedUserDecisionsRef.current) {
            setSaving(false);
            setAlertMessage({
              message: "Successfully updated your decision.",
              messageType: AlertType.SuccessMessage,
            });
            fetchOutgoingDecisions();
            isMountedUserDecisionsRef.current = false;
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          if (isMountedUserDecisionsRef.current) {
            setSaving(false);
            setAlertMessage({
              message: "An error occurred while updating your decision.",
              messageType: AlertType.ErrorMessage,
            });
            isMountedUserDecisionsRef.current = false;
          }
        });
    },
    [fetchOutgoingDecisions, getAccessTokenSilently, isAuthenticated, isLoading, user?.sub, userProfile.user_id]
  );

  function submitForm(event: any) {
    event.preventDefault();

    if (userDecision === UserDecisionEnum.Neither) return;

    var userDecisionModel = {
      user_id: user?.sub,
      for_user: userProfile.user_id,
      decision: userDecision === UserDecisionEnum.Interested,
    } as UserDecisionModel;
    submitUserDecision(userDecisionModel);
  }

  useEffect(() => {
    fetchUserImage(userProfile.event_id, userProfile.user_id);
    return () => {
      isMountedRefProfileImage.current = false;
    };
  }, [fetchUserImage, userProfile.event_id, userProfile.user_id]);

  return (
    <>
      <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Profile Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-center p-1">
          {userImage && (
            <div className="d-flex justify-content-center">
              <img src={userImage} className="img-fluid" alt="User Profile Pic" />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImageModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show}
        centered
        onHide={() => {
          setShow(false);
        }}
        onExited={() => {
          closeModal();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{userProfile.first_name + " " + userProfile.last_name}</Modal.Title>
        </Modal.Header>
        <form onSubmit={submitForm}>
          <Modal.Body>
            {userImage && (
              <>
                <div className="d-flex justify-content-center">
                  <span className="border border-3 rounded-3 p-1 mb-3">
                    <img
                      src={userImage}
                      className="img-fluid"
                      alt="User Profile Pic"
                      style={{ maxHeight: "200px" }}
                      onClick={() => {
                        setShowImageModal(true);
                      }}
                    />
                  </span>
                </div>
              </>
            )}
            {!userImage && (
              <div className="d-flex justify-content-center">
                <span className="border border-3 rounded-3 p-1 mb-3">
                  <svg
                    className="bd-placeholder-img card-img-top"
                    width="150"
                    height="150"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    aria-label="Placeholder"
                    preserveAspectRatio="xMidYMid slice"
                    focusable="false"
                  >
                    <title>Placeholder</title>
                    <rect width="100%" height="100%" fill="#868e96"></rect>
                    <UserProfileIcon className="m-0" width="50" height="50" fill="white" x={50} y={50} />
                  </svg>
                </span>
              </div>
            )}
            <table className="table">
              <tbody>
                <tr>
                  <td>User Id</td>
                  <td className="text-end">
                    <span
                      className={
                        "badge" +
                        (userProfile.friendly_user_id
                          ? userProfile.gender === GenderEnum.Male
                            ? " text-bg-primary"
                            : " text-bg-danger"
                          : " text-bg-secondary")
                      }
                    >
                      {userProfile.friendly_user_id ?? "N/A"}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Name</td>
                  <td
                    className="text-end"
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    {userProfile.first_name + " " + userProfile.last_name}
                  </td>
                </tr>
                <tr>
                  <td>Age</td>
                  <td className="text-end">{userProfile.age}</td>
                </tr>
                <tr>
                  <td>City</td>
                  <td className="text-end">{userProfile.city}</td>
                </tr>
                <tr>
                  <td>Citizenship</td>
                  <td className="text-end">{userProfile.citizenship}</td>
                </tr>
                <tr>
                  <td>Height</td>
                  <td className="text-end">
                    {userProfile.height_value
                      ? `${userProfile.height_value}` + (userProfile.height_unit ? ` ${userProfile.height_unit}` : "")
                      : ""}
                  </td>
                </tr>
                <tr>
                  <td>Willing to relocate?</td>
                  <td className="text-end">{userProfile.relocation ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>{userProfile.gender === GenderEnum.Male ? "Expects spouse to wear hijab?" : "Wears hijab?"}</td>
                  <td className="text-end">{userProfile.hijab ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Education</td>
                  <td
                    className="text-end"
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    {userProfile.education && getEducationLevelFriendlyName(userProfile.education)}
                    {!userProfile.education && ""}
                  </td>
                </tr>
                <tr>
                  <td>Profession</td>
                  <td
                    className="text-end"
                    style={{
                      wordBreak: "break-all",
                    }}
                  >
                    {userProfile.profession ?? ""}
                  </td>
                </tr>
                <tr>
                  <td>Relationship status</td>
                  <td className="text-end">
                    {userProfile.relationship_status &&
                      getRelationshipStatusFriendlyName(userProfile.relationship_status)}
                    {!userProfile.relationship_status && ""}
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">Hobbies</td>
                  <td className="text-end">
                    <button className="btn btn-primary" type="button" onClick={() => setShowInterestsModal(true)}>
                      {countInterests(userProfile.interests) + " selected"}
                    </button>
                  </td>
                </tr>
                {userProfile.facebook_url ? (
                  <tr>
                    <td>Facebook</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {userProfile.facebook_url && (
                        <>
                          {isValidHttpUrl(userProfile.facebook_url) ? (
                            <a href={userProfile.facebook_url} target={"_blank"} rel="noreferrer">
                              <img src={FacebookLogo} alt="Facebook Logo" width={30} height={30} />
                            </a>
                          ) : (
                            <>{userProfile.facebook_url}</>
                          )}
                        </>
                      )}
                      {!userProfile.facebook_url && <span>N/A</span>}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {userProfile.linkedIn_url ? (
                  <tr>
                    <td>LinkedIn</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {userProfile.linkedIn_url && (
                        <>
                          {isValidHttpUrl(userProfile.linkedIn_url) ? (
                            <a href={userProfile.linkedIn_url} target={"_blank"} rel="noreferrer">
                              <img src={LinkedInLogo} alt="LinkedIn Logo" width={35} height={30} />
                            </a>
                          ) : (
                            <>{userProfile.linkedIn_url}</>
                          )}
                        </>
                      )}
                      {!userProfile.linkedIn_url && <span>N/A</span>}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                {userProfile.instagram_url ? (
                  <tr>
                    <td>Instagram</td>
                    <td
                      className="text-end"
                      style={{
                        wordBreak: "break-all",
                      }}
                    >
                      {userProfile.instagram_url && (
                        <>
                          {isValidHttpUrl(userProfile.instagram_url) ? (
                            <a href={userProfile.instagram_url} target={"_blank"} rel="noreferrer">
                              <img src={InstagramLogo} alt="Instagram Logo" width={30} height={30} />
                            </a>
                          ) : (
                            <>{userProfile.instagram_url}</>
                          )}
                        </>
                      )}
                      {!userProfile.instagram_url && <span>N/A</span>}
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                <tr>
                  <td>Are they interested?</td>
                  <td className="text-end">
                    {incomingDecision === UserDecisionEnum.Interested && <span>Interested</span>}
                    {incomingDecision === UserDecisionEnum.NotInterested && <span>Not Interested</span>}
                    {incomingDecision === UserDecisionEnum.Neither && <span>Pending</span>}
                  </td>
                </tr>
                {incomingDecision === UserDecisionEnum.Interested &&
                  outgoingDecision === UserDecisionEnum.Interested && (
                    <tr>
                      <td>Phone Number</td>
                      <td className="text-end">
                        {userProfile.phone_number && <span>{userProfile.phone_number}</span>}
                        {!userProfile.phone_number && <span>N/A</span>}
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            <div className="d-flex justify-content-center">
              <ButtonGroup>
                <ToggleButton
                  id="interested-button"
                  type="radio"
                  variant="outline-success"
                  name="interested"
                  value={UserDecisionEnum.Interested}
                  checked={userDecision === UserDecisionEnum.Interested}
                  onChange={(e) => {
                    if (e.currentTarget.value === UserDecisionEnum.Interested) {
                      setUserDecision(UserDecisionEnum.Interested);
                    } else {
                      setUserDecision(UserDecisionEnum.NotInterested);
                    }
                  }}
                >
                  Interested
                </ToggleButton>
                <ToggleButton
                  id="not-interested-button"
                  type="radio"
                  variant="outline-danger"
                  name="interested"
                  value={UserDecisionEnum.NotInterested}
                  checked={userDecision === UserDecisionEnum.NotInterested}
                  onChange={(e) => {
                    if (e.currentTarget.value === UserDecisionEnum.Interested) {
                      setUserDecision(UserDecisionEnum.Interested);
                    } else {
                      setUserDecision(UserDecisionEnum.NotInterested);
                    }
                  }}
                >
                  Not Interested
                </ToggleButton>
              </ButtonGroup>
            </div>
            <AlertMessage
              {...alertMessage}
              clearMessage={() => {
                setAlertMessage({
                  ...alertMessage,
                  message: undefined,
                });
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                setShow(false);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              type="submit"
              disabled={saving || userDecision === UserDecisionEnum.Neither}
            >
              {saving && (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
              {!saving && <>Save</>}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={showInterestsModal} onHide={() => setShowInterestsModal(false)} fullscreen="md-down">
        <Modal.Header closeButton>
          <Modal.Title>Hobbies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {Object.keys(InterestsEnum).map((key, index) => {
              return (
                <div className="col-11 col-sm-3 col-md-4 col-xl-4 d-flex m-2" key={index}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={key}
                      id={"flexCheck" + index}
                      checked={userProfile?.interests?.includes(key)}
                      disabled
                    />
                    <label className="form-check-label" htmlFor={"flexCheck" + index}>
                      <div>
                        <div className="d-inline">{getInterestIcon(key as InterestsEnum)}</div>
                        <div className="d-inline ms-1 align-middle">{key}</div>
                      </div>
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowInterestsModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserProfileModal;
