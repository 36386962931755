import { User } from "@auth0/auth0-react";
import { Collapse } from "react-bootstrap";

interface Props {
    user: User | undefined;
}
function LoggedInNotification({ user }: Props) {
    return (
        <>
            <Collapse in={user !== undefined}>
                <div className="py-1 col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                    <span className="text-muted">Logged in as: {user?.email}</span>
                </div>
            </Collapse>
        </>
    );
}

export default LoggedInNotification;
