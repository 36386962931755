import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import AlertMessage, { AlertMessageModel, AlertType } from "./AlertMessage";
import { AzureFunctionUri, checkUserRole } from "./Constants";
import { Auth0User } from "./models/Auth0User";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";

interface Props {
    userRole: UserRoleModel;
    auth0User: Auth0User;
    closeModal: Function;
}
function ViewUserModal({ userRole, auth0User, closeModal }: Props) {
    const [show, setShow] = useState(true);
    const [alertMessage, setAlertMessage] = useState({
        message: undefined,
        messageType: AlertType.InfoMessage,
    } as AlertMessageModel);
    // const [showAssignUsersModal, setShowAssignUsersModal] = useState(false);
    const [savingRole, setSavingRole] = useState(false);
    const [selectedRole, setSelectedRole] = useState<string | -1>(-1);
    const [usersEventCode, setUsersEventCode] = useState(auth0User.event_code);
    const [usersPaymentRef, setUsersPaymentRef] = useState(auth0User.payment_reference);

    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);

    const assignRole = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRef.current = true;
        setSavingRole(true);

        const accessToken = await getAccessTokenSilently();

        var userRole = {
            user_id: auth0User.user_id,
            role: selectedRole,
            event_code: usersEventCode,
            payment_reference: usersPaymentRef,
        } as UserRoleModel;

        fetch(AzureFunctionUri + "api/UserRoles/" + encodeURIComponent(auth0User.user_id), {
            method: "PUT",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userRole),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. ViewUserModal (PUT: UserRoles): " + response.statusText + "(" + response.status + ")");
                }
                if (isMountedRef) {
                    setSavingRole(false);
                    setAlertMessage({
                        message: "Successfully assigned role. Please refresh the page.",
                        messageType: AlertType.SuccessMessage,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) {
                    setSavingRole(false);
                    setAlertMessage({
                        message: "An error occurred while assigning role.",
                        messageType: AlertType.ErrorMessage,
                    });
                }
            });
    }, [auth0User.user_id, getAccessTokenSilently, isAuthenticated, isLoading, selectedRole, usersEventCode, usersPaymentRef]);

    async function deleteUser(userId: string) {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRef.current = true;
        setSavingRole(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/User/" + encodeURIComponent(auth0User.user_id), {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. ViewUserModal (DELETE: User): " + response.statusText + "(" + response.status + ")");
                }

                if (isMountedRef) {
                    setSavingRole(false);
                    setAlertMessage({
                        message: "Successfully deleted user. Please refresh the page.",
                        messageType: AlertType.SuccessMessage,
                    });
                    closeModal();
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) {
                    setSavingRole(false);
                    setAlertMessage({
                        message: "An error occurred while deleting user.",
                        messageType: AlertType.ErrorMessage,
                    });
                }
            });
    }

    function submitForm(event: any) {
        event.preventDefault();

        if (selectedRole !== -1) {
            assignRole();
        }
    }

    return (
        <>
            <Modal
                show={show}
                centered
                onHide={() => {
                    setShow(false);
                }}
                onExited={() => {
                    closeModal();
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View User</Modal.Title>
                </Modal.Header>
                <form onSubmit={submitForm}>
                    <Modal.Body>
                        <AlertMessage
                            {...alertMessage}
                            clearMessage={() => {
                                setAlertMessage({
                                    ...alertMessage,
                                    message: undefined,
                                });
                            }}
                        />
                        <div className="mb-2">
                            <span>
                                <b>Id: </b>
                            </span>
                            {auth0User.user_id} <br />
                            <span>
                                <b>Name: </b>
                            </span>
                            {auth0User.name} <br />
                            <span>
                                <b>Email: </b>
                            </span>
                            {auth0User.email} <br />
                            <span>
                                <b>Role: </b>
                            </span>
                            {auth0User.role}
                        </div>
                        <div>
                            <b>Assign New Role:</b>
                        </div>
                        <div className="mb-3">
                            <select
                                className="form-select"
                                aria-label="Assign Roles Select"
                                value={selectedRole}
                                disabled={savingRole}
                                onChange={(e) => {
                                    setSelectedRole(e.target.value);
                                }}
                            >
                                <option value="-1">Select...</option>
                                {checkUserRole(userRole, [UserRoles.Admin]) && <option value={UserRoles.Admin}>Admin</option>}
                                {checkUserRole(userRole, [UserRoles.Admin]) && <option value={UserRoles.EventOwner}>Event Owner</option>}
                                {checkUserRole(userRole, [UserRoles.EventOwner, UserRoles.Admin]) && (
                                    <>
                                        <option value={UserRoles.Facilitator}>Facilitator</option>
                                        <option value={UserRoles.User}>User</option>
                                        <option value={UserRoles.Other}>Non-User</option>
                                    </>
                                )}
                            </select>
                        </div>
                        <div>
                            <b>Event Code:</b>
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="eventCodeFormControlInput"
                                name="event_code"
                                placeholder=""
                                value={usersEventCode ?? ""}
                                onChange={(e) => {
                                    setUsersEventCode(e.target.value);
                                }}
                                disabled={savingRole}
                            />
                        </div>
                        <div>
                            <b>Payment Reference:</b>
                        </div>
                        <div className="mb-3">
                            <input
                                type="text"
                                className="form-control"
                                id="paymentRefFormControlInput"
                                name="payment_reference"
                                placeholder=""
                                value={usersPaymentRef ?? ""}
                                onChange={(e) => {
                                    setUsersPaymentRef(e.target.value);
                                }}
                                disabled={savingRole}
                            />
                        </div>
                        {checkUserRole(userRole, [UserRoles.EventOwner, UserRoles.Admin]) && (
                            <>
                                <div>
                                    <b>Delete this User:</b>
                                </div>
                                <div className="mb-3">
                                    <div>Deleting this user will delete all their data from the system. This action is irreversible.</div>
                                    <div>
                                        <button className="btn btn-danger" type="button" onClick={() => setShowDeleteUserModal(true)}>
                                            Delete
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* {auth0User.role === UserRoles.Facilitator && (
                            <>
                                <div>
                                    <b>Assign Users to this Facilitator:</b>
                                </div>
                                <div>
                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        onClick={() => {
                                            setShowAssignUsersModal(true);
                                        }}
                                    >
                                        Assign Users to Facilitator
                                    </button>
                                </div>
                            </>
                        )} */}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="btn btn-secondary"
                            type="button"
                            onClick={() => {
                                setShow(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button className="btn btn-primary" type="submit" disabled={savingRole || selectedRole === -1 || selectedRole === "-1"}>
                            {savingRole && (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                            {!savingRole && <>Save</>}
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal centered={true} show={showDeleteUserModal} onHide={() => setShowDeleteUserModal(false)} aria-labelledby="deleteUserModal">
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title id="deleteUserModalLabel">Delete User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-1">
                        Are you sure you want to delete this User? <br />
                        Name: {auth0User.name} <br />
                        Email: {auth0User.email} <br />
                        Event Code: {auth0User.event_code} <br />
                        Role: {auth0User.role} <br />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => setShowDeleteUserModal(false)}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            deleteUser(auth0User.user_id);
                            setShowDeleteUserModal(false);
                        }}
                        disabled={auth0User.user_id ? false : true}
                    >
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
            {/* {showAssignUsersModal && <AssignUsersModal user={auth0User} users={users} closeModal={() => setShowAssignUsersModal(false)} />} */}
        </>
    );
}

export default ViewUserModal;
