import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AlertType } from "./AlertMessage";
import UserRoleModel from "./models/UserRoleModel";

interface Props {
    userRole: UserRoleModel;
    getUserRole: Function;
    setAlertMessage: Function;
}
function PaymentSuccess({ userRole, getUserRole, setAlertMessage }: Props) {
    const history = useHistory();

    useEffect(() => {
        setAlertMessage({
            message: "Successfully completed payment. Payment will be verified in a few minutes. Meanwhile, you can start modifying your User Profile.",
            messageType: AlertType.SuccessMessage,
        });
        getUserRole();
        history.push("/UserProfile");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
}

export default PaymentSuccess;
