import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AlertType } from "./AlertMessage";

interface Props {
    setAlertMessage: Function;
}
function PaymentFailed({ setAlertMessage }: Props) {
    const history = useHistory();

    useEffect(() => {
        setAlertMessage({
            message: "Payment failed. Please contact an admin if you are facing trouble.",
            messageType: AlertType.ErrorMessage,
        });
        history.push("/UserProfile");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <></>;
}

export default PaymentFailed;
