import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { AlertType } from "./AlertMessage";
import { checkUserRole } from "./Constants";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";

interface Props {
    children?: React.ReactNode | React.ReactNode[];
    currentUserRole: UserRoleModel;
    isLoadingUserRole: boolean;
    userRoles?: UserRoles[];
    setAlertMessage: Function;
}

function ProtectedRoute({
    children,
    currentUserRole = { role: UserRoles.Other } as UserRoleModel,
    isLoadingUserRole = false,
    userRoles = [UserRoles.Other, UserRoles.User, UserRoles.Facilitator, UserRoles.EventOwner, UserRoles.Admin],
    setAlertMessage,
}: Props) {
    const { isLoading, isAuthenticated } = useAuth0();

    const [hasCorrectScope, setCorrectUserScope] = useState(true);

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            setAlertMessage({
                message: "Please log in to access this page.",
                messageType: AlertType.ErrorMessage,
            });
        }
        if (!isLoading && isAuthenticated && !isLoadingUserRole) {
            if (!checkUserRole(currentUserRole, userRoles)) {
                setAlertMessage({
                    message: "Access Denied! You are not authorized to access this page.",
                    messageType: AlertType.ErrorMessage,
                });
                setCorrectUserScope(false);
            } else {
                setCorrectUserScope(true);
            }
        }
    }, [currentUserRole, isAuthenticated, isLoading, isLoadingUserRole, setAlertMessage, userRoles]);

    return (
        <>
            {!isLoading &&
                (isAuthenticated ? (
                    userRoles.length > 0 ? (
                        hasCorrectScope ? (
                            children
                        ) : (
                            <Redirect to={"/"} />
                        )
                    ) : (
                        children
                    )
                ) : (
                    <>
                        <Redirect to={"/"} />
                    </>
                ))}
        </>
    );
}

export default ProtectedRoute;
